import React, { useEffect, useState } from 'react';
import "./Register.css";
import Button1 from "../components/Button1";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import { useLogOut } from "../hooks/useLogOut";
//import { useTokenManagement } from "../hooks/useTokenManagement";
import { useGetUser } from "../hooks/useGetUser";
import { useDate } from "../hooks/useDate";
import { useEncryption } from "../hooks/useEncryption";
import { Timestamp } from 'firebase/firestore';
import { auth } from './Firebase';
import logo from '../images/logo.svg';
import "./Swollet.css";
import telegram from '../images/telegram.svg';
import discord from '../images/discord.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import ln from '../images/ln.svg';
import Sidebar from "../components/Sidebar";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react';
import Footer from "../components/Footer";
/*import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';*/

const Swollet: React.FC = () => {
    const { state: { user } } = useAuthContext();
    const { state: { userData } } = useUserContext();
    const { logout } = useLogOut();
    const { getUser } = useGetUser();
    const navigate = useNavigate();
    //const { refreshAccessToken } = useTokenManagement();
    const { encryptPassword } = useEncryption();
    //const { dateFunctions } = useDate();
    const { formatTimestamp } = useDate();
    const [isMobile, setIsMobile] = useState(false);

    const handleSignOut = async () => {
        try {
            await logout();
            navigate("/login")
        } catch (error) {
            console.log(error);
        }
    };

    const handleCourses = () => {
        navigate("/courses");
    }

    useEffect(() => {

        //refreshAccessToken();
        const getU = async () => {
            await getUser(user.accessToken);

            /*const { formattedDateTimeWithOffset, timestamp } = date();

            console.log("Date: ", formattedDateTimeWithOffset, " ", timestamp);*/

            //const currentDate = new Date();
            //
            //const dateString = dateFunctions.toStringDate(currentDate, { withTime: true, withMonthName: true });
            //console.log("1) Date : ", date());
            
            // Create a timestamp from a JavaScript Date object
            //const timestampFromDate = firebase.firestore.Timestamp.fromDate(new Date());
            //console.log("timestampFromDate: ", timestampFromDate);

        }

        if (user == null) {
          navigate("/login");
        }
        else{
            console.log("user is: ", user)
        }
        console.log("Spiros here: ", user);

        getU();
        
        /*const timestamp = Timestamp.now(); // Replace this with your actual Firebase Timestamp
        const formattedTimestamp = formatTimestamp(timestamp);
        console.log(formattedTimestamp);

        console.log("777: ", Timestamp.fromDate(formattedTimestamp));*/

        //console.log("^^ date: ", date());
        console.log("encryption: ", encryptPassword("tony9999"))
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
      
          // Initial check
        updateIsMobile();
      
          // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);
      
          // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };

    }, [user]);


    return (
        <div>

            <div className="wrapper register-back">
                <div className="content">
                    <header className="header">
                        <div className="header-wrapper ">
                            <a href="/" className="logo">
                                <img src={logo}  alt=""/>
                            </a>
                            {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary">Messages</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={handleSignOut}>Logout</MenuItem>
                                    </MenuList>
                                    </Menu>
                                </div>
                                
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <button className="button btn-primary">Messages</button>
                                    <button onClick={handleSignOut} className="button btn-primary">
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>

                    </header>


                    <section className="hero">
                        <div className="hide">
                            <Sidebar/>
                        </div>
                        
                        <div className="first-div">
                            <div className="centered-container">
                                <h1>Welcome {userData?.userName} !!!</h1>
                                
                            </div>

                            <div className="centered-container1" style={{"marginTop": "100px"}}>
                                <div className="box-course1" onClick={handleCourses}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Courses</p>
                                </div>
                                <div className="box-course1" onClick={() => {navigate("/gamecenter")}}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Trading game</p>
                                </div>
                                <div className="box-course1" onClick={() => {navigate("/profile")}}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Profile Setings</p>
                                </div>
                           
                            
                                <div className="box-course1" onClick={() => {navigate("/gamecenter")}}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Leaderboard</p>
                                </div>
                                <div className="box-course1" onClick={() => {navigate("/gamecenter")}}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Analytics</p>
                                </div>
                                <div className="box-course1"  onClick={() => {navigate("/gamecenter")}}>
                                    <div className="center-content">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="fonts">Articles</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>

            </div>
        </div>
    )
}

export default Swollet;
