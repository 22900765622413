import React, { useState } from "react";

//All the svg files
import logo from '../images/logo.svg';
import Home from "../images/home-solid.svg";
import Team from "../images/social.svg";
import Calender from "../images/sceduled.svg";
import Courses from "../images/courses-icon1.png";
import PowerOff from "../images/power-off-solid.svg";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';

const Container = styled.div`
  position: fixed;
  background-color: var(--black);
  margin-bottom: 50px;

  .active {
    border-right: 4px solid var(--white);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

interface ButtonProps {
  clicked: boolean;
}

const Button = styled.button<ButtonProps>`
  background-color: black;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before,
  &::after {
    content: "";
    background-color: white;
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: ${(props: any) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props: any) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props: any) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props: any) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
  
`;

const SidebarContainer = styled.div`
  background-color: black;
  width: 3.5rem;
  height: 50vh;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

const logo1 = styled.div`
  width: 2rem;

  img {
    width: 100%;
    height: auto;
  }
`;

interface SlickBarProps {
  clicked: boolean;
}

const SlickBar = styled.ul<SlickBarProps>`
  color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  border-right: 2px solid green;
  padding: 2rem 0;

  position: absolute;
  top: 6rem;
  left: 0;

  width: ${(props: any) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: white;
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;

  display: flex;
  padding-left: 1rem;

  &:hover {
    border-right: 4px solid white;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }

  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

interface TextProps {
  clicked: boolean;
}

const Text = styled.span<TextProps>`
  width: ${(props: any) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
`;

interface ProfileProps {
  clicked: boolean;
}

const Profile = styled.div<ProfileProps>`
  width: ${(props: any) => (props.clicked ? "14rem" : "3rem")};
  height: 3rem;

  padding: 0.5rem 1rem;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props: any) => (props.clicked ? "9rem" : "0")};

  background-color: black;
  color: white;

  transition: all 0.3s ease;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      border: 2px solid grey;
      padding: 2px;
    }
  }
`;

interface DetailsProps {
  clicked: boolean;
}

const Details = styled.div<DetailsProps>`
  display: ${(props: any) => (props.clicked ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  padding: 0 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    display: inline-block;
  }

  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: grey;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Logout = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;

  img {
    width: 100%;
    height: auto;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
      brightness(100%) contrast(126%);
    transition: all 0.3s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const Sidebar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [profileClick, setprofileClick] = useState(false);
  const handleProfileClick = () => setprofileClick(!profileClick);

  const { state: { userData } } = useUserContext();

  return (
    <div style={{"backgroundColor": "black"}} >
    <Container>
      <Button clicked={click} onClick={() => handleClick()}>
        Click
      </Button>
      <SidebarContainer>
      <Profile clicked={click}>
          <img
            onClick={() => handleProfileClick()}
            src="https://static.thenounproject.com/png/5034901-200.png"
            alt="Profile"
          />
          <Details clicked={click}>
            <Name>
              <h4>{userData?.userName}</h4>
              <a href="/profile">view&nbsp;profile</a>
            </Name>
          </Details>
        </Profile>
        <SlickBar clicked={click}>
          <Item
            onClick={() => setClick(false)}
            to="/home"
          >
            <img src={Home} alt="Home" />
            <Text clicked={click}>Home</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            to="/courses"
          >
            <img src={Calender} alt="Team" />
            <Text clicked={click}>Courses</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            to="/community"
          >
            <img src={Team} alt="Calender" />
            <Text clicked={click}>Community</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            to="/profile"
          >
            <img src={Calender} alt="Documents" />
            <Text clicked={click}>Profile Settings</Text>
          </Item>
          <Item
            onClick={() => setClick(false)}
            to="/gamecenter"
          >
            <img src={Calender} alt="Game Center" />
            <Text clicked={click}>Game Center</Text>
          </Item>
        </SlickBar>

        
      </SidebarContainer>
    </Container>
    </div>
  );

  /*const activeClass = (activeClassName: any, defaultClassName: any) => {
    return (match: any, location: any) => {
      if (match) {
        return activeClassName;
      } else {
        return defaultClassName;
      }
    };
  }

  return (
    <div className="container">
      <button className="button-sidebar" onClick={() => handleClick()}>
        Click
      </button>
      <div className="sidebar-container">
        <div className="logo">
          <img src={logo2} alt="logo1" />
        </div>
        <ul className={`slickBar ${click ? "open" : ""}`}>
          <NavLink
            to="/"
            exact
            activeClassName="active"
            className="item"
            onClick={() => setClick(false)}
          >
            <img src={Home} alt="Home" />
            <span className={`text ${click ? "show" : ""}`}>Home</span>
          </NavLink>
          <NavLink
            to="/team"
            activeClassName="active"
            className="item"
            onClick={() => setClick(false)}
          >
            <img src={Team} alt="Team" />
            <span className={`text ${click ? "show" : ""}`}>Team</span>
          </NavLink>
          <NavLink
            to="/calender"
            activeClassName="active"
            className="item"
            onClick={() => setClick(false)}
          >
            <img src={Calender} alt="Calender" />
            <span className={`text ${click ? "show" : ""}`}>Calender</span>
          </NavLink>
          <NavLink
            to="/documents"
            activeClassName="active"
            className="item"
            onClick={() => setClick(false)}
          >
            <img src={Documents} alt="Documents" />
            <span className={`text ${click ? "show" : ""}`}>Documents</span>
          </NavLink>
          <NavLink
            to="/projects"
            activeClassName="active"
            className="item"
            onClick={() => setClick(false)}
          >
            <img src={Documents} alt="Projects" />
            <span className={`text ${click ? "show" : ""}`}>Projects</span>
          </NavLink>
        </ul>
        <div className={`profile ${profileClick ? "open" : ""}`}>
          <img
            onClick={() => handleProfileClick()}
            src="https://picsum.photos/200"
            alt="Profile"
            className="profile-image"
          />
          <div className={`details ${profileClick ? "show" : ""}`}>
            <div className="name">
              <h4>Jhon&nbsp;Doe</h4>
              <a href="/#">View Profile</a>
            </div>
            <button className="logout-button">
              <img src={PowerOff} alt="PowerOff" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );*/
};

export default Sidebar;