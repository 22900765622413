import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

/*export const useCourse = () => {
    const [courseBody, setCourseBody] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
  
    const course = async (idToken: string, courseName: any) => {
      setIsLoading(true);

      console.log("idToken is: ", idToken);
  
      try {
        const response = await fetch(`/course/${courseName}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // SOS when we send the body like here
          },
          body: JSON.stringify({ idToken }),
        });
  
        // If the API call is successful, update the allCourses state
        if (response.ok) {

            console.log("HEYYYYY IS OK!");
            const json = await response.json();

            console.log("response: ", response);
            console.log("json: ", json);

            return json;

            setCourseBody(json);

            console.log("coursebody: ", courseBody)
        }
  
        setIsLoading(false);
      } catch (error) {
            console.log("FALSEEEE");
            setIsLoading(false);
        // Handle error if needed
      }
    };
  
    return { courseBody, course, isLoading };
};
*/

export const useCourse = () => {
    const [courseBody, setCourseBody] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
  
    const course = async (idToken: string, courseName: any) => {
      setIsLoading(true);

      console.log("idToken is: ", idToken);
      console.log("courseNmae: ", courseName);
  
      try {
        const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/course/${courseName}?idToken=${idToken}`);
  
        // If the API call is successful, update the allCourses state
        if (response.ok) {
            console.log("HEYYYYY IS OK!");
            const json = await response.json();
            console.log("response from random hook: ", response);
            console.log("json from random hook: ", json);
            return json;
        }
  
        setIsLoading(false);
      } catch (error) {
            console.log("FALSEEEE");
            setIsLoading(false);
        // Handle error if needed
      }
    };

    return { courseBody, course, isLoading };
  };
