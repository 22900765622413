import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button1 from '../components/Button1';
import ln from '../images/ln.svg';
import logo from '../images/logo.svg';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

const TermsOfService = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [textSlideIndex, setTextSlideIndex] = useState(1);
  const navigate = useNavigate();
  let interval: NodeJS.Timeout;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {

      const updateIsMobile = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };
  
      // Initial check
      updateIsMobile();
  
      // Event listener for window resize
      window.addEventListener('resize', updateIsMobile);

      return () => {
          clearInterval(interval);
          window.removeEventListener('resize', updateIsMobile);
      };

  }, []);

  return (
    <div>
        <div className="wrapper">
            <div className="content">
                <header className="header">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo}  alt=""/>
                        </a>
                        {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/business")}}>Business</MenuItem>
                                        {/*<MenuItem className="button btn-primary" onClick={() => {navigate("/login")}}>Login</MenuItem>*/}
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <Button1 name="Business" url="business"/>
                                    {/*<Button1 name="Login" url="login"/>*/}
                                </div>
                            )}
                    </div>
                </header>
                <header className="header sticky">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo} alt=""/>
                        </a>
                        {/*<a href="/business"><button className="button btn-primary">For Business</button></a>*/}
                        <Button1 name="Business" url="business"/>
                        <Button1 name="Login" url="login"/>
                    </div>
                </header>

                <section className="hero">
                    <div className="container">
                      <div className="hero-wrapper">
                        <h1 className="h1 green-gradient">Terms of Service<br /></h1>
                      </div>

                      <div className="about-wrapper">
                        <div className="aos-init aos-animate">
                            <h3 className="green-gradient" style={{marginTop: "50px"}}>Introduction</h3>
                            <p className="large">
                                Welcome to Swollet, a mobile application that gamifies financial education, offers on-chain credentials, and provides a platform for virtual trading games. These Terms and Conditions govern your use of the Swollet app.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Acceptance of Terms</h3>
                            <p className="large">
                                By accessing or using Swollet, you agree to be bound by these Terms and Conditions.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Modification of Terms</h3>
                            <p className="large">
                                Swollet reserves the right to modify these terms at any time. Changes will be communicated through the app or via email.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>User Account and Security</h3>
                            <p className="large">
                                To use Swollet, you must create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Intellectual Property Rights</h3>
                            <p className="large">
                                All content on Swollet, including text, graphics, logos, and software, is the property of Swollet or its content suppliers and protected by international copyright and trademark laws.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>User Conduct</h3>
                            <p className="large">
                                You agree to use Swollet only for lawful purposes. Prohibited conduct includes, but is not limited to, infringement of intellectual property, harassment, and submitting false information.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Financial Education and Virtual Trading</h3>
                            <p className="large">
                                Swollet provides educational content on finance, crypto, Web3, AI, and other entrepreneurial aspects. The virtual trading game is a simulation and should not be considered financial advice.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>On-Chain Credentials and NFTs</h3>
                            <p className="large">
                                Swollet offers on-chain credentials in the form of NFTs. These NFTs are subject to the terms of the blockchain they operate on.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Privacy and Data Protection</h3>
                            <p className="large">
                                Your privacy is important to us. Swollet collects and uses personal data as outlined in our Privacy Policy.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Third-Party Links and Services</h3>
                            <p className="large">
                                Swollet may contain links to third-party websites or services. We are not responsible for the content or practices of these sites.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Disclaimer of Warranties and Liability</h3>
                            <p className="large">
                                Swollet is provided "as is" without warranty of any kind. We do not guarantee that the app will be error-free or uninterrupted.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Indemnification</h3>
                            <p className="large">
                                You agree to indemnify and hold harmless Swollet and its employees from any claim arising from your use of the app.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Termination and Account Cancellation</h3>
                            <p className="large">
                                Swollet reserves the right to terminate your account for any reason. You may cancel your account at any time.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Governing Law and Dispute Resolution</h3>
                            <p className="large">
                                These terms shall be governed by the laws of Ireland. Any disputes arising under these terms will be resolved through arbitration in Dublin, Ireland.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Miscellaneous</h3>
                            <p className="large">
                                If any part of these terms is held invalid or unenforceable, the remaining parts will remain in full force and effect.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>Contact Information</h3>
                            <p className="large">
                                For questions or concerns regarding these terms, please contact us at <a href="mailto:info@swollet.xyz">info@swollet.com</a>.
                            </p>
                        </div>
                      </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
  )
}

export default TermsOfService