import React, {useEffect, useState} from 'react';
import { useLogOut } from "../hooks/useLogOut";
import "./Login.css";
import "./Profile.css";
import "./Courses.css";
import arrow from '../images/arrow.svg';
import { useNavigate } from "react-router-dom";
import logo from '../images/logo.svg';
import Sidebar from "../components/Sidebar";
import { useAuthContext } from '../hooks/useAuthContext';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react';

const GameCenter = () => {
    const { logout } = useLogOut();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const { state: { user } } = useAuthContext();

    const handleSignOut = async () => {
        try {
            await logout();
            navigate("/login")
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
      
          // Initial check
        updateIsMobile();
      
          // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);
      
          // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };

    }, [user]);


    return (
    <div>
        <div className="wrapper register-back">
            <div className="content">

                <header className="header">
                        <div className="header-wrapper ">
                            <a href="/" className="logo">
                                <img src={logo}  alt=""/>
                            </a>

                            {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/home")}}>Home</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/courses")}}>Courses</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/profile")}}>Profile</MenuItem>
                                        <MenuItem className="button btn-primary">Logout</MenuItem>
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <button className="button btn-primary">Messages</button>
                                    <button onClick={handleSignOut} className="button btn-primary">
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                </header>

                    <section className="hero">
                        <div className="hide">
                            <Sidebar/>
                        </div>
                        <h1 className="centered-container">Game Center</h1>

                        <h3 className="centered-container" style={{marginTop: "150px"}}>Coming soon ... </h3>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default GameCenter
