import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "./Register.css";
import Button1 from "../components/Button1";
import { useNavigate } from "react-router-dom";
//import { UserAuth } from '../context/AuthContext';
import { Form, Alert } from "react-bootstrap";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import { UserModel } from '../context/UserContext';
import { useRegister } from "../hooks/useRegister";
import { useLogin } from "../hooks/useLogin";

// rendered in the /register route
const RegisterPhone: React.FC = () => {
    const navigate = useNavigate();
    const { setUpRecaptha } = useLogin();
    const { state: { user } } = useAuthContext();
    const { dispatch: userDispatch } = useUserContext();
    
    const { googleRegister, appleRegister, emailRegister } = useRegister();

    const [phone, setPhone] = useState<string>("");
    const [otp, setOtp] = useState<any>();
    const [flag, setFlag] = useState<boolean>(false);
    const [confirmationResult, setConfirmationResult] = useState<any>();
    const [error, setError] = useState<string>("");

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPhone(value);
    };

    function handleClickLogin(): void {
        navigate("/login");
    }

    /*const handlePhoneRegister = async () => {
        try {
            await phoneRegister(phone);
            if(user != null) {
              navigate("/home");
            }
            console.log("user: ", user );
        } catch (error) {
            console.log(error);
        }
    }*/

    const getOtp = async (e: any) => {
        e.preventDefault();
        console.log(phone);

        if (phone === "" || phone === undefined){
          console.log("Please enter a valid phone phone!");
          return setError("Please enter a valid phone number!");
        }

        try {
          //const response = await setUpRecaptha(phone);
          //setResult(response);
          const confirmationResult = await setUpRecaptha(phone);
          setConfirmationResult(confirmationResult);
          setFlag(true);

        } catch (error) {
          console.log(error);
          setError(`Error: ${error}`);
        }
    };


    const verifyOtp = async (e: any) => {
        e.preventDefault();
        setError("");
        if (otp === "" || otp === null) return;

        try {

            // Now, you can call the 'confirm' method on the 'confirmationResult' object
            const userCredential = await confirmationResult.confirm(otp);

            const user = userCredential.user;

            const { phoneNumber, uid } = user;

            // Define a function to generate the userData object with default values
            const generateDefaultUserData = (): UserModel => {
                const defaultValues: UserModel = {
                    uid,
                    password: null,
                    userName: null,
                    phoneNumber: phoneNumber,
                    email: null, // Use the renamed variable here
                    personalInfo: null,
                    bio: null,
                    imageUrl: null,
                    friends: [],
                    blockList: [],
                    location: null,
                    creationTime: null,
                    lastSignInTime: null,
                    active: null,
                    accountValue: null,
                    know: null,
                    xp: 0,
                    myGoals: [],
                    mySkills: [],
                    faceID: null,
                    admin: null,
                    deviceToken: null,
                    isiOS: null,
                    premium: null,
                };

                return defaultValues;
            };

            const userData: UserModel = generateDefaultUserData();

            localStorage.setItem('user', JSON.stringify(user));

            localStorage.setItem('userData', JSON.stringify(userData));

            user({type: 'LOGIN', payload: confirmationResult.user});

            userDispatch({type: 'LOGIN', payload: userData})

            navigate("/home");

        } catch (error) {
          console.log(error);
          setError(`Error: ${error}`);
        }
    };

    useEffect(() => {
        if (user != null) {
          navigate("/home");
        }
        else{
          console.log("user is: " , null)
        }
    }, [user]);


    return (
        <div>
            <div className="wrapper">
                <div className="content">
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div className="register-title">Register via phone</div>

                    <div className="register-content" style={{ display: !flag ? "block" : "none" }}>
                        <div className="form__group field input1" >
                            <input 
                                onChange={handleChangePhone} 
                                name="phone" 
                                placeholder="Phone" 
                                value={phone} 
                                type="phone"
                                className="form__field"  
                                required 
                            />
                            <label htmlFor="email" className="form__label">Phone</label>
                            
                            <div id="recaptcha-container"></div>
                        </div>

                        <button name="Submit" className="btn btn-primary a1" onClick={getOtp}>Register</button>
                    </div>

                    <div className="register-content" style={{ display: flag ? "block" : "none" }}>
                            <div className="form__group field input1">
                                <input 
                                    onChange={(e) => setOtp(e.target.value)}
                                    name="otp" 
                                    placeholder="Enter OTP" 
                                    value={otp} 
                                    type="otp"
                                    className="form__field"  
                                    required 
                                />
                                <label htmlFor="email" className="form__label">OTP</label>
                            </div>
                            <button name="Submit" className="btn btn-primary a1" onClick={verifyOtp}>Verify</button>  
                    </div>
                    <div className="have-account">
                        <div className="a1">Example: [+3069867123402]</div>
                        <div className="a1">Or if you have acount:</div>
                        <div className="button-account a1" onClick={handleClickLogin}><Button1 name="Login" url="login"/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPhone;