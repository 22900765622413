import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import {
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    OAuthProvider,
    onAuthStateChanged,
    isSignInWithEmailLink, 
    signInWithEmailLink,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    createUserWithEmailAndPassword,
    getIdToken,
} from 'firebase/auth';
import { auth } from '../pages/Firebase';
import "../pages/Login.css";
//import { useTokenManagement } from "../hooks/useTokenManagement";

interface AuthState {
    user: any; // Replace 'any' with the type for your user object
}
  
  // Define the type for the action
type AuthAction = {
    type: 'LOGIN' | 'LOGOUT';
    payload?: any; // Replace 'any' with the type for your payload, if needed
};

// Create the initial state
const initialState: AuthState = {
    user: null,
};

export const AuthContext = createContext<{ state: AuthState; dispatch: React.Dispatch<AuthAction>; }>({ state: initialState, dispatch: () => {}, });

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
      case 'LOGIN':
        return { ...state, user: action.payload };
      case 'LOGOUT':
        return { ...state, user: null };
      default:
        return state;
    }
  };

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    //const [user, setUser] = useState<any>({});
    const [state, dispatch] = useReducer(authReducer, initialState);
    //const { refreshAccessToken } = useTokenManagement();

    /*useEffect(() => {
        // we take from the localStrage the user, if it is there a value
        //const user = JSON.parse(localStorage.getItem("user"));
        
        const userJson = localStorage.getItem("user");

        if (userJson !== null) {
            try {
              const user = JSON.parse(userJson);
              if (user) {
                dispatch({ type: 'LOGIN', payload: user });
              }
            } catch (error) {
              console.error('Error parsing JSON data:', error);
            }
        }
        //if the user exists, we have a value for the user

    }, [])*/

    useEffect(() => {
      const userJson = localStorage.getItem("user");

      /*const checkAndUpdateToken = async (user) => {

        console.log("user.stsTokenManager.accessTokenExpirationTime: ", user.stsTokenManager.accessTokenExpirationTime);
        console.log("Date.now(): ", Date.now());

        if (user.stsTokenManager.accessTokenExpirationTime < Date.now()) {
          try {

            console.log("AuthContext: ");
            const idToken = await getIdToken(auth, user);
            console.log(idToken);
            // Update the user object with the new access token
            user.stsTokenManager.accessToken = idToken;

            // Dispatch the updated user to your context
            dispatch({ type: 'LOGIN', payload: user });
          } catch (error) {
            console.error('Error refreshing access token:', error);
          }
        }
      };*/

      const checkAndUpdateToken = async (user: any) => {
        try {
          console.log("I am In checkAndUpdateToken!!");

          const idTokenResult = await auth?.currentUser?.getIdToken();
          const idToken = idTokenResult;

          console.log("idTokenResult: ", idTokenResult, " idToken: ", idToken);

          // Update the user object with the new access token
          user.stsTokenManager.accessToken = idToken;

          // Dispatch the updated user to your context
          dispatch({ type: 'LOGIN', payload: user });
        } catch (error) {
          console.error('Error refreshing access token:', error);
        }
      };

      if (userJson !== null) {
        try {
          const user = JSON.parse(userJson);
          if (user) {
            console.log("Hellooo!!!!");
            dispatch({ type: 'LOGIN', payload: user });

            // Check if the access token has expired and refresh it
            checkAndUpdateToken(user);
          }
        } catch (error) {
          console.error('Error parsing JSON data:', error);
        }
      }

      const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
        if (authUser) {
          // Check if the access token has expired and refresh it
          checkAndUpdateToken(authUser);
        }
      });

      return () => {
        unsubscribe();
      };
    }, []);

    return(
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};
