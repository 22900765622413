import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Your Google Maps API Key
const apiKey = 'YOUR_API_KEY';

// Center of the map
const defaultCenter = {
  lat: 37.7749, // Replace with your default latitude
  lng: -122.4194, // Replace with your default longitude
};

const Location = () => {
  return (
    <div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={{ height: '400px', width: '100%' }}
          center={defaultCenter}
          zoom={12} // You can adjust the initial zoom level as needed
        >
          {/* Add a marker for the geopoint */}
          <Marker position={defaultCenter} />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Location
