import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useUserContext } from './useUserContext';
import { UserModel } from '../context/UserContext';
import {
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    OAuthProvider,
    onAuthStateChanged,
    isSignInWithEmailLink, 
    signInWithEmailLink,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    createUserWithEmailAndPassword,
} from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { auth } from '../pages/Firebase';
import { useDate } from "./useDate";
import { useCreateUser } from "./useCreateUser";
import { useEncryption } from "./useEncryption";

// To register the user
export const useRegister = () => {
//function useRegister(firstName, lastName, email, username, password) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()
    const { dispatch: userDispatch } = useUserContext();
    const { formatTimestamp } = useDate();
    const { createUser } = useCreateUser();
    const { encryptPassword } = useEncryption();

    const generateRandomString = (length: number) => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let randomString = "";

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          randomString += charset.charAt(randomIndex);
        }

        return randomString;
    };

    const googleRegister = async () => {
        const provider = new GoogleAuthProvider();

        // Optional: Set additional custom parameters for the provider
        // For example, to specify which Google accounts to allow:
        provider.setCustomParameters({ prompt: 'select_account' });

        //const d = date();
        const now = new Date();
        const timestamp = Timestamp.fromDate(now);

        try {
            const result = await signInWithPopup(auth, provider);

            // The 'result' object contains the user credential (UserCredential) if successful.
            // You can access the user information through 'result.user'.
            // For example, result.user.displayName will give you the user's display name.

            // Define a function to generate the userData object with default values
            const { email, displayName, uid } = result.user;

            // Define a function to generate the userData object with default values
            const generateDefaultUserData = (): UserModel => {
                const defaultValues: UserModel = {
                    uid,
                    password: null,
                    userName: displayName,
                    phoneNumber: null,
                    email,
                    personalInfo: {
                        firstName: null,
                        middleName: null,
                        lastName: null,
                        birthDate: null,
                    },
                    bio: null,
                    imageUrl: null,
                    friends: [],
                    blockList: [],
                    location: null,
                    creationTime: timestamp,
                    lastSignInTime: timestamp,
                    active: true,
                    accountValue: 10000,
                    know: null,
                    xp: 0,
                    myGoals: [],
                    mySkills: [],
                    faceID: null,
                    admin: null,
                    deviceToken: null,
                    isiOS: false,
                    premium: false,
                };

                return defaultValues;
            };

            const userData: UserModel = generateDefaultUserData();

            console.log("UserModel: ", userData);

            let accessToken = (result as any).user.stsTokenManager.accessToken;

            // YOU SHOULD UNCOMMENT THIS
            // await createUser(accessToken, userData);

            //await createUser((result.user as { stsTokenManager: { accessToken: string } })?.stsTokenManager?.accessToken, userData);

            // user has authentication info ( the data from dispatch({type: 'LOGIN', payload: result.user}))
            localStorage.setItem('user', JSON.stringify(result.user));

            // userData has personal information about every user info 
            // ( the data from  userDispatch({ type: 'LOGIN', payload: userData });)
            localStorage.setItem('userData', JSON.stringify(userData));

            //AuthContext
            dispatch({type: 'LOGIN', payload: result.user})

            // Dispatch the LOGIN action with the userData
            // UserContext
            userDispatch({ type: 'LOGIN', payload: userData });

            console.log('$$ User signed in:', result.user);
            console.log('$$ User email:', result.user.email);
            // You can access the user object from the result if needed.

            // You can also handle the registration process here, but in this simplified version, we're returning the user credential.
            //setUser(result.user);

        } catch (error: any) {
            if (error.code === 'account-exists-with-different-credential') {
                // Handle the case where the user has an existing account with a different provider.
                // You may want to show a message to the user or provide options to merge accounts.
                console.error('Account exists with different credential, you cant Register with that account, you should use another account:', error);
            } else {
                console.error('Google Sign-In Error:', error);
            }
        }
    };

    const appleRegister = async() => {
        const provider = new OAuthProvider("apple.com");

        provider.setCustomParameters({});

        try{
            const result = await signInWithPopup(auth, provider);
           //setUser(result.user);

            localStorage.setItem('user', JSON.stringify(result.user));
            dispatch({type: 'LOGIN', payload: result.user});

            console.log('User signed in with Apple:', result.user);
        } catch (error) {
            console.error('Apple Sign-In Error:', error);
        };
    }

    const emailRegister = async (email: string, password: string) => {
        try {
            // Create a new user with the provided email and password
            const result = await createUserWithEmailAndPassword(auth, email, password);

            // The 'result' object contains the user credential (UserCredential) if registration is successful.
            // You can access the user information through 'result.user'.
            // For example, result.user.displayName will give you the user's display name (though, in this case, the user doesn't have a display name yet).

            // You can also handle additional registration steps here if needed.
            // For example, you can update the user profile with a display name, additional information, etc.
            // You can use result.user.updateProfile() to set additional properties.

            // For example:
            // await result.user.updateProfile({
            //   displayName: 'John Doe',
            //   photoURL: 'https://example.com/profile-picture.jpg',
            // });

            // Optionally, you can send a verification email to the user if email verification is required.
            // For example, you can call result.user.sendEmailVerification().

            //setUser(result.user);
            const { email: userEmail, uid } = result.user;

            // get the current date
            //const { formatTimestamp } = date();
            //const timestamp = Timestamp.now(); // Replace this with your actual Firebase Timestamp
            //const formattedTimestamp = formatTimestamp(timestamp);
            const now = new Date();
            const timestamp = Timestamp.fromDate(now);

            const encryptedPassword = encryptPassword(password);

            // Extract the first 5 characters from the uid
            const first5CharactersOfUid = uid.slice(0, 5);
            // Create the userName by concatenating "newuser#" and the random string
            const userName = `newuser#${first5CharactersOfUid}`;

            // Define a function to generate the userData object with default values
            const generateDefaultUserData = (): UserModel => {
                const defaultValues: UserModel = {
                    uid,
                    password: encryptedPassword,
                    userName: userName,
                    phoneNumber: null,
                    email: userEmail, // Use the renamed variable here
                    personalInfo: {
                        firstName: null,
                        middleName: null,
                        lastName: null,
                        birthDate: null,
                    },
                    bio: null,
                    imageUrl: null,
                    friends: [],
                    blockList: [],
                    location: null,
                    creationTime: timestamp,
                    lastSignInTime: timestamp,
                    active: true,
                    accountValue: 10000,
                    know: null,
                    xp: 0,
                    myGoals: [],
                    mySkills: [],
                    faceID: null,
                    admin: null,
                    deviceToken: null,
                    isiOS: false,
                    premium: false,
                };

                return defaultValues;
            };

            const userData: UserModel = generateDefaultUserData();

            localStorage.setItem('user', JSON.stringify(result.user));
            localStorage.setItem('userData', JSON.stringify(userData));

            let accessToken = (result as any).user.stsTokenManager.accessToken;

            // YOU SHOULD UNCOMMENT THIS
            // await createUser(accessToken, userData);

            dispatch({type: 'LOGIN', payload: result.user});
            userDispatch({ type: 'LOGIN', payload: userData });

            console.log('User registered:', result.user);
        } catch (error) {
            // Handle any potential errors during the registration process, if desired.
            // For example, you can log the error or display a user-friendly error message to the user.
            console.error('Email Registration Error:', error);
        }
    };

    return { googleRegister, appleRegister, emailRegister }
}