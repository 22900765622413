import React from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { Select } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import "../css/Courses.css";
import ButtonCourse from '../components/ButtonCourse';
import finance from '../images/finance.png';
import "./Course.css";

const FinanceCourseContent: React.FC = () => {

   // const { user } = useAuthContext();
   const { state: { user } } = useAuthContext();

    return (
    <div>
            <div className="centered-container">
                <img src={finance} alt="Logo" className="logo"/>
            </div>
            <div className="wrapper">
                <Accordion allowToggle className="courses">
                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                                Inflation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">
                            <ButtonCourse name="01 How Inflation Works" url="finance/howinflationworks"/>
                            
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                                Investment
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">
                            <ButtonCourse name="01 Investment Calculator" url="finance/investmentcalculator"/>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                            Main
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">

                            <ButtonCourse name="01 Introduction to investing" url="finance/IntroToInvesting"/>

                            <ButtonCourse name="02 Understanding risk and reward" url="finance/understandingriskandreward"/>

                            <ButtonCourse name="03 Different types of investments" url="finance/differenttypesofinvestments"/>

                            <ButtonCourse name="04 Setting financial goals" url="finance/settingfinancialgoals"/>

                            <ButtonCourse name="05 Building a diversified portfolio" url="finance/buildingadiversifiedportfolio"/>

                            <ButtonCourse name="06 Making informed investment decisions" url="finance/makinginformedinvestmentdecisions"/>

                            <ButtonCourse name="07 Monitoring and adjusting investments" url="finance/monitorAdjusInvest"/>

                            <ButtonCourse name="08 What is the stock market?" url="finance/whatisthestockmarket"/>

                            <ButtonCourse name="09 Investing like Warren Buffett" url="finance/investinglikewarrenbuffett"/>

                            <ButtonCourse name="10 Investing in Alphabet (Google)" url="finance/investinginalphabet(Google)"/>

                            <ButtonCourse name="11 Investing in Amazon" url="finance/investinginamazon"/>

                            <ButtonCourse name="12 Investing in Meta" url="finance/investinginmeta"/>

                            <ButtonCourse name="13 Investing in Netflix" url="finance/investinginnetflix"/>

                            <ButtonCourse name="14 Investing in Microsoft" url="finance/investinginmicrosoft"/>

                            <ButtonCourse name="15 An Introduction to SPACs" url="finance/IntroToSPACs"/>

                            <ButtonCourse name="16 Analyzing Tesla's Valuation" url="finance/analyteslaval"/>

                            <ButtonCourse name="17 How to analyze AirBnB" url="finance/analyteslaval"/>

                            <ButtonCourse name="18 Intro to private equity" url="finance/IntroToprivateequity"/>

                            <ButtonCourse name="19 Investing in art" url="finance/investinginart"/>

                            <ButtonCourse name="20 Peer-to-peer lending" url="finance/peertopeerlending"/>

                            <ButtonCourse name="21 Equity crowdfunding" url="finance/equitycrowdfunding"/>

                            <ButtonCourse name="22 Introduction to Central Banks" url="finance/IntroTocentralbanks"/>

                            <ButtonCourse name="23 Introduction to interest rates" url="finance/introtointerestrates"/>

                            <ButtonCourse name="24 Protecting your portfolio from the trade war" url="finance/protectingyourportfoliofromthetradewar"/>

                            <ButtonCourse name="25 How to recession-proof your portfolio" url="finance/Howtoreces-portfolio"/>

                            <ButtonCourse name="26 Understanding economic theories" url="finance/understandingeconomictheories"/>

                            <ButtonCourse name="27 Deciding between Saving, Investing, or Trading" url="finance/decidingbetweensavinginvestingortrading"/>

                            <ButtonCourse name="28 How to work out how much to invest" url="finance/howtoworkouthowmuchtoinvest"/>

                            <ButtonCourse name="29 How to choose yourwealth manager" url="finance/howtochoosehyourwealthmanager"/>

                            <ButtonCourse name="30 How to improve your credit score" url="finance/howtoimproveyourcreditscore"/>

                            <ButtonCourse name="31 Managing your debts" url="finance/managingyourdebts"/>

                            <ButtonCourse name="32 Renting or buying? Which is better?" url="finance/rentingorbuyingwhichisbetter"/>

                            <ButtonCourse name="33 How to achieve Financial indepedence & retire early" url="finance/achieveFinancialindependenceandretireearly"/>

                            <ButtonCourse name="34 Choosing a broker" url="finance/choosingabroker"/>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                            Investing 101
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">

                            <ButtonCourse name="01 Why even invest?" url="finance/whyeveninvest"/>

                            <ButtonCourse name="02 The basic concepts of investing" url="finance/thebasicconceptsofinvesting"/>

                            <ButtonCourse name="03 Active or passive?" url="finance/activeorpassive"/>

                            <ButtonCourse name="04 Diversification" url="finance/diversification"/>

                            <ButtonCourse name="05 How to get started investing" url="finance/howtogetstartedinvesting"/>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                            ETFs
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">
                            <ButtonCourse name="01 What are ETFs" url="finance/whatareETFs"/>

                            <ButtonCourse name="02 Types of ETFs" url="finance/typesofETFs"/>

                            <ButtonCourse name="03 Active ETFs and smart beta" url="finance/activeETFsandsmartbeta"/>

                            <ButtonCourse name="04 Building an ETF portfolio" url="finance/buildinganETFportfolio"/>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                            Portfolio construction
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">
                            <ButtonCourse name="01 Why diversification is important?" url="finance/isdiversificationimportant"/>

                            <ButtonCourse name="02 How to spread your risk" url="finance/howtospreadyourrisk"/>

                            <ButtonCourse name="03 Measuring investment success" url="finance/measuringinvestmentsuccess"/>

                            <ButtonCourse name="04 Tools to get started" url="finance/toolstogetstarted"/>
                        </AccordionPanel>
                    </AccordionItem>

                </Accordion>
            </div>
        </div>
    )
}

export default FinanceCourseContent;
