import React, { useState, useEffect } from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Profile.css";
import arrow from '../images/arrow.svg';
import { useNavigate } from "react-router-dom";
import { useUpdate } from "../hooks/useUpdate";
import { useAuthContext } from '../hooks/useAuthContext';
import { useGetUser } from "../hooks/useGetUser";

const Email = () => {
  const { state: { userData } } = useUserContext();
  const { dispatch } = useUserContext();
  const { state: { user } } = useAuthContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>();
  const { updateUser } = useUpdate();
  const { getUser } = useGetUser();

  const handleSave = async () => {

    const fieldToUpdate = "email";
    const newValue = email;

    console.log("Before update: ", fieldToUpdate, " ", newValue);

    await updateUser(fieldToUpdate, newValue, user.stsTokenManager.accessToken);

    const userData = await getUser(user.stsTokenManager.accessToken);

    localStorage.setItem('userData', JSON.stringify(userData));
    dispatch({type: 'LOGIN', payload: userData})

    /*const userJson = localStorage.getItem('userData');
    const storedUserData = userJson ? JSON.parse(userJson) : null;

    if (storedUserData) {
      storedUserData.email = email;

      try {
        localStorage.setItem('userData', JSON.stringify(storedUserData));
        dispatch({type: 'LOGIN', payload: storedUserData})
      } catch (error) {
        // Handle localStorage error, e.g., it's full or unavailable
        console.error('Error saving to localStorage:', error);
      }
    }*/

    navigate("/profile")
  }

  useEffect(() => {

    console.log("I am in to the Email.tsx");
    console.log("email: ", email);

  }, [email])

  return (
    <div>
        <div className="wrapper">
            <div className="content">
              <div className="login-title">Email </div>
              <button onClick={() => {navigate("/profile")}} className="button btn-primary" style={{"color": "black"}}>
                Cancel
              </button>
              
              <div className="login-content">
                  <div className="form__group field input1">
                      <input
                          name="lang" 
                          id="lang" 
                          placeholder={`${userData?.email}`}
                          className="form-field"
                          onChange={(event) => setEmail(event.target.value)}
                          required
                      />
                      <label htmlFor="email"  className="form-label" >Your current e-mail</label>
                      
                  </div>
                  <div className="form__group field input1">
                    <button className="button btn-primary" onClick={handleSave}>
                        Save
                    </button>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Email
