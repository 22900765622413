import React, {useState} from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { useUpdate } from "../hooks/useUpdate";
import { useAuthContext } from '../hooks/useAuthContext';
import { useGetUser } from "../hooks/useGetUser";
import { Timestamp } from 'firebase/firestore';

const PersonalInfo = () => {
  const { state: { userData } } = useUserContext();
  const { dispatch } = useUserContext();
  const { state: { user } } = useAuthContext();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<any>();
  const { updateUser } = useUpdate();
  const { getUser } = useGetUser();

  const handleSave = async () => {
    //const storedUserData = JSON.parse(localStorage.getItem('userData'));

    const fieldToUpdate = "personalInfo.firstName";
    const newValue = firstName;
    await updateUser(fieldToUpdate, newValue, user.stsTokenManager.accessToken);

    const fieldToUpdate2 = "personalInfo.lastName";
    const newValue2 = lastName;
    await updateUser(fieldToUpdate2, newValue2, user.stsTokenManager.accessToken);

    const fieldToUpdate3 = "personalInfo.middleName";
    const newValue3 = middleName;
    await updateUser(fieldToUpdate3, newValue3, user.stsTokenManager.accessToken);

    const fieldToUpdate4 = "personalInfo.birthDate";
    const newValue4 = birthDate;
    await updateUser(fieldToUpdate4, newValue4, user.stsTokenManager.accessToken);

    const userData = await getUser(user.stsTokenManager.accessToken);

    localStorage.setItem('userData', JSON.stringify(userData));
    dispatch({type: 'LOGIN', payload: userData})

    navigate("/profile")
  }


  return (
    <div>
        <div className="wrapper">
            <div className="content">
                <div className="login-title"> Personal Information </div>
                <div className="login-content">
                    <div className="form__group field input1">
                        <input
                            name="firstName"
                            id="firstName"
                            placeholder={`${userData?.personalInfo?.firstName}`}
                            className="form-field"
                            onChange={(event) => setFirstName(event.target.value)}
                            required
                        />
                        <label htmlFor="firstName" className="form-label" >Legal First Name</label>
                    </div>

                    <div className="form__group field input1">
                        <input
                            name="middleName"
                            id="middleName"
                            placeholder={`${userData?.personalInfo?.middleName}`}
                            className="form-field"
                            onChange={(event) => setMiddleName(event.target.value)}
                            required
                        />
                        <label htmlFor="middleName" className="form-label" >Legal Middle Name (Optional)</label>
                    </div>

                    <div className="form__group field input1">
                        <input
                            name="lastName"
                            id="lastName"
                            placeholder={`${userData?.personalInfo?.lastName}`}
                            className="form-field"
                            onChange={(event) => setLastName(event.target.value)}
                            required
                        />
                        <label htmlFor="lastName" className="form-label" >Legal Last Name</label>
                    </div>

                    <div className="form__group field input1">
                        <input
                            name="birthDate"
                            id="birthDate"
                            placeholder={`${userData?.personalInfo?.birthDate}`}
                            className="form-field"
                            /*onChange={(event) => setBirthDate(event.target.value)}*/
                            required
                            disabled
                        />
                        <label htmlFor="birthDate" className="form-label" >Date of Birth (disabled for now)</label>
                    </div>

                    <div className="form__group field input1 centered-container" >
                      <button className="button btn-primary">
                          Save
                      </button>
                    </div>
                    
                </div>
                <div className="centered-container" style={{"marginTop": "70px"}}>
                    <button onClick={() => {navigate("/profile")}} className="button btn-primary" style={{"color": "black"}}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PersonalInfo
