import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, Auth, UserCredential } from 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyChc1wu4Dv48DP5FOUCsdyytzeXWTHNJcY",
  authDomain: "swollet-25943.firebaseapp.com",
  databaseURL: "https://swollet-25943-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "swollet-25943",
  storageBucket: "swollet-25943.appspot.com",
  messagingSenderId: "594829809371",
  appId: "1:594829809371:web:19f663e7717c1f2bfa1efe",
  measurementId: "G-LW6E4VSV74"
};
/*if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  // Use the Firebase Emulator Suite configuration for local development
  firebaseConfig.authDomain = 'localhost';
  firebaseConfig.apiKey = 'AIzaSyAgNsUs8ryT6NZ-GWehqDv_qckIvwwxx4Y';
  firebaseConfig.projectId = 'swollet-25943';
}*/



/*export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result: UserCredential) => {

      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
    })
    .catch((error: any) => {
      console.log(error);
    });
};*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);