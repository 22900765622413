import React, { useRef } from 'react';
import Home from "./pages/Home";
import Business from "./pages/Business";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterEmail from "./pages/RegisterEmail";
import RegisterPhone from "./pages/RegisterPhone";
import LoginEmail from "./pages/LoginEmail";
import LoginPhone from "./pages/LoginPhone";

import FillInfo from "./pages/FillInfo";

import Swollet from "./pages/Swollet";
import Courses from "./pages/Courses";
import GameCenter from "./pages/GameCenter";
import Community from "./pages/Community";
import FinanceCourseContent from "./pages/FinanceCourseContent";
import TechCourseContent from "./pages/TechCourseContent";
import Web3CourseContent from "./pages/Web3CourseContent";

import CryptoCourseContent from "./pages/CryptoCourseContent";

import Profile from "./pages/Profile";
import Bio from "./pages/Bio";
import Location from "./pages/Location";
import Email from "./pages/Email";
import PersonalInfo from "./pages/PersonalInfo";
import Username from "./pages/Username";
import Language from "./pages/Language";

import Test from "./pages/Test";

import InvestmentCalculator from "./pages/InvestmentCalculator";
import HowInflationWorks from "./pages/HowInflationWorks";
/*import BlockchainBasics from "./pages/Crypto/BlockchainBasics";
import IntroToBitcoin from "./pages/Crypto/IntroToBitcoin";
import WhatIsCrypto from "./pages/Crypto/WhatIsCrypto";
import Cryptography from "./pages/Crypto/Cryptography";
import Consensus from "./pages/Crypto/Consensus";
import Bonus from "./pages/Crypto/Bonus";
import Limitations from "./pages/Crypto/Limitations";
import IntroToCryptoTrading from "./pages/Crypto/IntroToCryptoTrading";
import IntroToXRP from "./pages/Crypto/IntroToXRP";
import IntroToBinance from "./pages/Crypto/IntroToBinance";
import IntroToEthereum from "./pages/Crypto/IntroToEthereum";
import SmartContract from "./pages/Crypto/SmartContract";
import IntroToDefi from "./pages/Crypto/IntroToDefi";*/
import Course from "./pages/Course";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import UserAgreement from "./pages/UserAgreement";
import CompetitionRules from "./pages/CompetitionRules";

import { AlertProvider } from "./context/AlertContext";
import { ChakraProvider } from "@chakra-ui/react";
import { useAuthContext } from './hooks/useAuthContext';
//2import { AuthContextProvider } from './context/AuthContext';
import { BrowserRouter, Routes, Route, Navigate,  useNavigate } from "react-router-dom"; // navigate is for the redirecting

function App() {
  //const { user } = useAuthContext();

  const { state: { user } } = useAuthContext();

  return (
    <AlertProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={!user ? <Home /> : <Navigate to="/home"/>} />
          <Route path="/business" element={!user ? <Business /> : <Navigate to="/home"/>} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/home"/>} />
          <Route path="/register" element={!user ? <Register /> : <Navigate to="/home"/>} />
          <Route path="/register/email" element={!user ? <RegisterEmail /> : <Navigate to="/home"/>} />
          <Route path="/register/phone" element={!user ? <RegisterPhone /> : <Navigate to="/home"/>} />
          <Route path="/login/email" element={!user ? <LoginEmail /> : <Navigate to="/home"/>} />
          <Route path="/login/phone" element={!user ? <LoginPhone /> : <Navigate to="/home"/>} />

          
          <Route path="/privacypolicy" element={!user ? <PrivacyPolicy/> : <Navigate to="/home" />} />
          <Route path="/termsofservice" element={!user ? <TermsOfService/> : <Navigate to="/home" />} />
          <Route path="/useragreement" element={!user ? <UserAgreement/> : <Navigate to="/home" />} />
          <Route path="/competitionrules" element={!user ? <CompetitionRules/> : <Navigate to="/home" />} />

          <Route path="/fill" element={user ? <FillInfo /> : <Navigate to="/"/>} />
          <Route path="/home" element={user ? <Swollet /> : <Navigate to="/" />} />
          <Route path="/courses" element={user ? <Courses /> : <Navigate to="/" />} />

          <Route path="/crypto" element={user ? <CryptoCourseContent /> : <Navigate to="/" />} />
          <Route path="/crypto/:id" element={user ? <Course/> : <Navigate to="/" />}/>
          
          {/*<Route path="/crypto/blockchainBasics" element={user ? <BlockchainBasics /> : <Navigate to="/" />} />
          <Route path="/crypto/introToBitcoin" element={user ? <IntroToBitcoin /> : <Navigate to="/" />} />
          <Route path="/crypto/whatIsCrypto" element={user ? <WhatIsCrypto /> : <Navigate to="/" />} />
          <Route path="/crypto/cryptography" element={user ? <Cryptography /> : <Navigate to="/" />} />
          <Route path="/crypto/bonus" element={user ? <Bonus /> : <Navigate to="/" />} />
          <Route path="/crypto/consensus" element={user ? <Consensus /> : <Navigate to="/" />} />
          <Route path="/crypto/limitations" element={user ? <Limitations /> : <Navigate to="/" />} />
          <Route path="/crypto/introToCryptoTrading" element={user ? <IntroToCryptoTrading /> : <Navigate to="/" />} />
          <Route path="/crypto/introToXRP" element={user ? <IntroToXRP /> : <Navigate to="/" />} />
          <Route path="/crypto/introToBinance" element={user ? <IntroToBinance /> : <Navigate to="/" />} />
          <Route path="/crypto/introToEthereum" element={user ? <IntroToEthereum /> : <Navigate to="/" />} />
          <Route path="/crypto/smartContract" element={user ? <SmartContract /> : <Navigate to="/" />} />
          <Route path="/crypto/introToDefi" element={user ? <IntroToDefi /> : <Navigate to="/" />} />*/}


          <Route path="/tech" element={user ? <TechCourseContent/> : <Navigate to="/" />}/>
          <Route path="/tech/:id" element={user ? <Course/> : <Navigate to="/" />}/>

          <Route path="/web3" element={user ? <Web3CourseContent /> : <Navigate to="/" />} />
          <Route path="/web3/:id" element={user ? <Course/> : <Navigate to="/" />}/>

          <Route path="/finance" element={user ? <FinanceCourseContent /> : <Navigate to="/" />} />
          <Route path="/finance/:id" element={user ? <Course/> : <Navigate to="/" />}/>
          <Route path="/finance/investmentcalculator" element={user ? <InvestmentCalculator/> : <Navigate to="/" />}/>
          <Route path="/finance/howinflationworks" element={user ? <HowInflationWorks/> : <Navigate to="/" />}/>

          <Route path="/profile" element={user ? <Profile/> : <Navigate to="/" />} />
          <Route path="/email" element={user ? <Email/> : <Navigate to="/" />} />
          <Route path="/location" element={user ? <Location/> : <Navigate to="/" />} />
          <Route path="/personalinfo" element={user ? <PersonalInfo/> : <Navigate to="/" />} />
          <Route path="/username" element={user ? <Username/> : <Navigate to="/" />} />
          <Route path="/bio" element={user ? <Bio/> : <Navigate to="/" />} />
          <Route path="/language" element={user ? <Test/> : <Navigate to="/" />} />
          <Route path="/gamecenter" element={user ? <GameCenter/> : <Navigate to="/" />} />
          <Route path="/community" element={user ? <Community/> : <Navigate to="/" />} />


          {/*
          <Route path="/" element={ !user ? <Home /> : <Navigate to="/home"/>} />
          <Route path="/business" element={ !user ? <Business /> : <Navigate to="/home"/>} />
          <Route path="/login" element={ !user ? <Login /> : <Navigate to="/home"/>} />
          <Route path="/register" element={ !user ? <Register /> : <Navigate to="/home"/>} />
          <Route path="/register/email" element={ !user ? <RegisterEmail /> : <Navigate to="/home"/>} />
          <Route path="/register/phone" element={ !user ? <RegisterPhone /> : <Navigate to="/home"/>} />
          <Route path="/login/email" element={ !user ? <LoginEmail /> : <Navigate to="/home"/>} />
          <Route path="/login/phone" element={ !user ?<LoginPhone /> : <Navigate to="/home"/>} />
          */}
        </Routes>
      </BrowserRouter>
    </AlertProvider>

  );
}

export default App;

