import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import Button1 from '../components/Button1';

import logo from '../images/logo.svg';
import hero_ill from '../images/hero-ill.png';
import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';

import play_and_learn from '../images/play_and_learn.png';
import leaderboard from '../images/leaderboard.png';
import community from '../images/community.png';
import NFTs from '../images/NFT.png';

import even1 from '../images/even1.png';
import even2 from '../images/even2.png';
import even3 from '../images/even3.png';
import arrow from '../images/arrow.svg';

import about from '../images/about.png';
//import aboutbot from '../images/aboutbot.png';
import mail from '../images/mail.svg';

import telegram from '../images/telegram.svg';
import discord from '../images/discord.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import ln from '../images/ln.svg';

import envelope from '../images/envelope.svg';
import guides from '../images/guides.png';
import daily from '../images/daily.png';
import custom from '../images/custom.png';
import app from '../images/app.png';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

const Business: React.FC = () => {
    const [slideIndex, setSlideIndex] = useState(1);
    const [textSlideIndex, setTextSlideIndex] = useState(1);
    const navigate = useNavigate();
    let interval: NodeJS.Timeout;

    /*useEffect(() => {
      showSlides(slideIndex);
      showSlides(textSlideIndex);
      interval = setInterval(() => plusSlides(1), 6000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);*/
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {

        showSlides(slideIndex);
        showSlides(textSlideIndex);
        interval = setInterval(() => plusSlides(1), 6000);

        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
    
        // Initial check
        updateIsMobile();
    
        // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', updateIsMobile);
        };

    }, []);


    const handleClick = () => {
        window.location.href = 'https://tlzsdf13eyu.typeform.com/to/k30X3zeI';
    };
  
    function plusSlides(n: number) {
        showSlides(slideIndex + n);
    }
  
    function currentSlide(n: number) {
        showSlides(n);
        clearInterval(interval);
        interval = setInterval(() => plusSlides(1), 6000);
    }
  
    function showSlides(n: number) {
        let i;

        let slides = document.getElementsByClassName('sw-image') as HTMLCollectionOf<HTMLElement>;    // for the typescript
        let textSlides = document.getElementsByClassName('sw-content') as HTMLCollectionOf<HTMLElement>; // for the typescript
        let dots = document.querySelectorAll('.sw-item .number');
        let dotsMobile = document.querySelectorAll('.sw-pagination .number');

        if (n > slides.length) {
            setSlideIndex(1);
        } else if (n < 1) {
            setSlideIndex(slides.length);
        } else {
            setSlideIndex(n);
        }

        if (n > textSlides.length) {
            setTextSlideIndex(1);
        } else if (n < 1) {
            setTextSlideIndex(textSlides.length);
        } else {
            setTextSlideIndex(n);
        }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
            textSlides[i].style.display = 'none';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
            dotsMobile[i].className = dots[i].className.replace(' active', '');
        }

        slides[slideIndex - 1].style.display = 'block';
        textSlides[slideIndex - 1].style.display = 'block';
        dots[slideIndex - 1].className += ' active';
        dotsMobile[slideIndex - 1].className += ' active';
    }

    return (
    <div>
        

        <div className="wrapper">
            <div className="content">
                <header className="header">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo} alt=""/>
                        </a>
                        
                        {/*<button onClick={() => window.location.href = 'https://tlzsdf13eyu.typeform.com/to/k30X3zeI'} type="button" className="button btn-primary">Contact</button>*/}
                        {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/")}}>Home</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => window.location.href = 'https://tlzsdf13eyu.typeform.com/to/k30X3zeI'}>Contact</MenuItem>
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <Button1 name="Home" url="home"/>
                                    <button className="button btn-primary" onClick={() => window.location.href = 'https://tlzsdf13eyu.typeform.com/to/k30X3zeI'}>Contact</button>
                                </div>
                            )}
                    </div>
                </header>
                <header className="header sticky">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo} alt=""/>
                        </a>
                        
                        <button onClick={() => window.location.href = 'https://tlzsdf13eyu.typeform.com/to/k30X3zeI'} type="button" className="button btn-primary">Contact</button>
                        
                        <a className="btn-primary popup-opener" data-popup="subscribe">
                            <img src={envelope} alt=""/> Get early access
                        </a>
                    </div>
                    </header>

        
                <section className="hero">
                    <br />
                    <div className="container">
                        <div className="section-header">
                            <h2 className="green-gradient">Master the art of connecting with today's retail investors</h2>
                            <p className="large">Unlock the secrets to understanding, captivating, and effectively communicating with the modern retail investor like never before. Embrace the pulse of the latest trends and establish an unbreakable bond with this dynamic generation.</p>
                        </div>
                        <div className="sw-container">
                            <div className="sw-item sw-item-1" onClick={() => currentSlide(1)}>
                                <p className="number">01</p>
                                <p className="h3 green-gradient">Simplified How-To Guides</p>
                                <p className="small">Unlock the secrets of personal finance and delve into advanced investment strategies with our straightforward, no-nonsense tutorials.</p>
                            </div>
                            <div className="sw-item sw-item-2" onClick={() => currentSlide(2)}>
                                <p className="number">02</p>
                                <p className="h3 green-gradient">Up-to-the-Minute News</p>
                                <p className="small">Stay ahead of the game with bite-sized updates in just 3 minutes. Discover the meaning behind market trends and understand why it matters for the bigger picture.</p>
                            </div>
                            <div className="sw-item sw-item-3" onClick={() => currentSlide(3)}>
                                <p className="number">03</p>
                                <p className="h3 green-gradient">Tailored Experiences</p>
                                    <p className="small">Experience personalized educational content tailored to your unique interests and needs. Empower yourself and learn how to maximize the benefits of our services and beyond.</p>
                            </div>
                            <div className="sw-item sw-item-4" onClick={() => currentSlide(4)}>
                                <p className="number">04</p>
                                <p className="h3 green-gradient">Powerful Collaborations</p>
                                <p className="small">Join forces with us and showcase your brand's unrivaled value to our passionate, knowledge-hungry community. Through our exclusive app partnerships, your existing users gain access to our cutting-edge insights and wealth-building tools. Together, we'll transform the future.</p>
                            </div>
                            
                            <div className="sw-images">
                                <img className="sw-image" src={guides}></img>
                                <img className="sw-image" src={daily}></img>
                                <img className="sw-image" src={custom}></img>
                                <img className="sw-image" src={app}></img>
                            </div>
                        </div>
                        <div className="sw-content-container">
                            <div className="sw-content content">
                                <p className="h3 green-gradient">Simplified How-To Guides</p>
                                <p className="large">Unlock the secrets of finance and delve into advanced investment strategies with our straightforward, no-nonsense tutorials.</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Up-to-the-Minute News</p>
                                <p className="large">Stay ahead of the game with bite-sized updates in just 3 minutes. Discover the meaning behind market trends and understand why it matters for the bigger picture.</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Tailored Experiences</p>
                                <p className="large">Experience personalized educational content tailored to your unique interests and needs. Empower yourself and learn how to maximize the benefits of our services and beyond.</p>
                            </div>
                            <div className="sw-content">
                                <p className="h3 green-gradient">Powerful Collaborations</p>
                                <p className="large">Join forces with us and showcase your brand's unrivaled value to our passionate, knowledge-hungry community. Through our exclusive app partnerships, your existing users gain access to our cutting-edge insights and wealth-building tools. Together, we'll transform the future.</p>
                            </div>
                        </div>
                        <ul className="sw-pagination">
                            <li className="number" onClick={() => currentSlide(1)}>01</li>
                            <li className="number" onClick={() => currentSlide(2)}>02</li>
                            <li className="number" onClick={() => currentSlide(3)}>03</li>
                            <li className="number" onClick={() => currentSlide(4)}>04</li>
                        </ul>
                    </div>
                </section>

                <section className="even-more">
                    <div className="container">
                        <h3 className="h3 green-gradient">...and even more...</h3>
                    </div>
                    <div className="even-more-wrapper">
                        <div className="item" /*data-aos="fade-up"*/>
                            <img src={even1} alt=""/>
                            <h5>Daily analyst insights</h5>
                            <p>8 minutes of reading = 8 hours of research. Our  analysts provide digestible research  summaries with actionable insights.</p>
                        </div>
                        <div className="item" /*data-aos="fade-up"*/ data-aos-delay="100">
                            <img src={even2} alt=""/>
                            <h5>Promotional campaigns</h5>
                            <p>Promotional campaigns are the most effective way to engage with the modern retail investor community at scale. We support start-ups, scale-ups, and a large number of long-established financial service brands in achieving their marketing goals.</p>
                        </div>
                        <div className="item" /*data-aos="fade-up"*/ data-aos-delay="200">
                            <img src={even3} alt=""/>
                            <h5>Ad space</h5>
                            <p>We off ad space in our in-app community feed.</p>
                        </div>
                    </div>
                    <div className="even-more-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even1} alt=""/>
                                    <br />
                                    <br />
                                    <h5>Daily analyst insights</h5>
                                    <p>8 minutes of reading = 8 hours of research. Our  analysts provide digestible research  summaries with actionable insights.</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even2} alt=""/>
                                    <h5>Promotional campaigns</h5>
                                    <p>Promotional campaigns are the most effective way to engage with the modern retail investor community at scale. We support start-ups, scale-ups, and a large number of long-established financial service brands in achieving their marketing goals.</p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="item">
                                    <img src={even3} alt=""/>
                                    <br />
                                    <br />
                                    <h5>Promotional campaigns</h5>
                                    <p>Promotional campaigns are the most effective way to engage with the modern retail investor community at scale. We support start-ups, scale-ups, and a large number of long-established financial service brands in achieving their marketing goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pagination"></div>
                    </div>
                </section>

                <section className="faq">
                    <div className="container">
                        <h2 className="green-gradient">Ready to introduce yourself to our community?</h2>
                    </div>
                </section>

                <section className="contact" /*data-aos="fade-up"*/>
                    <div className="container">
                        <h5>Still have some questions? Reach out and we will help!</h5>
                        <center><button onClick={handleClick} type="button" className="button btn-primary">Get in touch</button></center>
                    </div>
                </section>

                <section className="about">
                    <div className="container">
                        <h2 className="green-gradient" /*data-aos="fade-up"*/>About Swollet</h2>
                        <div className="about-wrapper">
                            <div className="left-side">
                                {/*<div data-aos="fade-up">
                                    <h3 className="green-gradient">Who we are</h3>
                                    <p className="large">Swollet! The social learn-to-earn investing platform where anyone can learn about investing while earning our $KNOW cryptocurrency token. Swollet was founded in 2020 and is headquartered in Dublin, Ireland.</p>
                                </div>*/}
                                <div /*data-aos="fade-up"*/>
                                <h3 className="green-gradient">Our story</h3>
                                    <br/><p className="large">The idea for Swollet sparked in 2022 when our founders asked, "Why is learning about money and tech still so inaccessible?" They set out to create the most engaging educational platform ever - one that makes finance and emerging tech universally understood.</p>

                                    <br/><p className="large">With expertise in gaming, AI-driven personalization and social networking, our team of educators, designers and engineers brought Swollet to life. We're on a mission to empower people everywhere to take control of their financial futures and understand the technologies shaping our world.</p>

                                    <br/><p className="large">At Swollet, we're obsessed with making learning fun and effective. Our game-inspired courses break down complex concepts into easily digestible bits. Personalized recommendations help you master new material quickly. And our vibrant community of experts and fellow learners ensures you never feel alone.</p>

                                    <br/><p className="large">Whether you're a total beginner or seasoned expert, Swollet has the perfect courses for you. The future belongs to the financially and technologically literate. Together, we can all swol up and own it!</p>    
                                </div>
                            </div>
                            <div className="right-side" /*data-aos="fade-up"*/>
                                <img src={about} alt=""/>
                                
                            </div>
                        </div>
                    </div>
                </section>

                <div className="popup-wrapper" id="subscribe" hidden>
                    <div className="popup">
                        <div className="popup-close close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.1">
                                <path d="M20.4784 18.3612L14.1008 11.9836L20.4618 5.62265C21.0452 5.03928 21.0452 4.08469 20.4618 3.50133C19.8785 2.91796 18.9239 2.91796 18.3405 3.50133L11.9795 9.8623L5.63512 3.5179C5.05175 2.93454 4.09716 2.93453 3.5138 3.5179C2.93043 4.10126 2.93043 5.05586 3.5138 5.63922L9.8582 11.9836L3.49722 18.3446C2.91386 18.928 2.91386 19.8826 3.49722 20.4659C4.08059 21.0493 5.03518 21.0493 5.61854 20.4659L11.9795 14.105L18.357 20.4825C18.9404 21.0659 19.895 21.0659 20.4784 20.4825C21.0617 19.8992 21.0618 18.9445 20.4784 18.3612Z" fill="white"/>
                                </g>
                            </svg>
                        </div>
                        <div className="popup-content">
                            <img src={mail} alt=""/>
                            <p className="green-gradient title">Get early access<br />Join the waitlist to be one of the first testers! </p>
                            <div id="mc_embed_signup">
                                <div className="mailchimp-form">
                                    <form action="https://swollet.us5.list-manage.com/subscribe/post?u=ae47f162bf9213c0ffde5253e&amp;id=c13d1ecd81" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate>
                                        <div id="mc_embed_signup_scroll">
                                            <div className="mc-field-group form-group">
                                                <input type="email" className="required email" placeholder="Your e-mail" name="EMAIL" id="mce-EMAIL" />
                                            </div>
                                            <div id="mce-responses" className="clear form-response">
                                                <div className="response error" id="mce-error-response" style={{display:"none"}}></div>
                                                <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                                            </div>
                                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                                <input type="text" name="b_ae47f162bf9213c0ffde5253e_c13d1ecd81" tabIndex={Number("-1")} value="" />
                                            </div>
                                            <div className="clear">
                                                <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button btn-primary">Get early access</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="success-sent">
                    <p>Your email has been successfully added to the early access list</p>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.0875 5.51719L13.1391 15.975C12.8625 16.2937 12.4547 16.5 12 16.5C11.5875 16.5 11.2125 16.3313 10.9406 16.0594L6.44062 11.5547C5.07656 10.2 7.19531 8.07656 8.55938 9.43125L11.9156 12.7922L19.8609 3.50625C19.9641 3.38906 20.0812 3.32344 20.1937 3.24844C18.0469 1.24219 15.1734 0 12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 9.60938 23.2922 7.3875 22.0875 5.51719Z" fill="white"/>
                    </svg>
                </div>
        
        
        
                <footer className="footer">
                    <div className="footer-wrapper clearfix">
                        <div id="footer-left" className="clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                            <div className="footer-widget widget_text_icl clearfix">
                                <h4 style={{color: "#7EDC6F"}}>Swollet</h4>
                                <div className="textwidget">
                                    <ul className="list-info">
                                        <li><i className="fa fa-map-marker fa-lg"></i><span>Home</span></li>
                                        <li><i className="fa fa-phone fa-lg"></i><span>How it works</span></li>
                                        <li><i className="fa fa-envelope fa-lg"></i><span>About Swollet</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div id="footer-middle" className="clearfix">
                            <div className="footer-widget widget_nav_menu clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                                <h4 style={{color: "#7EDC6F"}}>Docs</h4>
                                <div className="menu-footer-menu-%ce%b5%ce%bb%ce%bb%ce%b7%ce%bd%ce%b9%ce%ba%ce%ac-container">
                                    <ul id="menu-footer-menu-%ce%b5%ce%bb%ce%bb%ce%b7%ce%bd%ce%b9%ce%ba%ce%ac" className="menu">
                                        <li id="menu-item-17212" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17212"><a href="https://swollet.gitbook.io/swollet/">Documentation</a>
                                        </li>
                                        <li id="menu-item-17213" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17213">
                                            Terms
                                        </li>
                                        <li id="menu-item-17214" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-17214"><a href="https://swollet.xyz/privacypolicy">Privacy Policy</a>
                                        
                                        
                                        
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div id="footer-right" className="clearfix">
                            <div className="footer-widget widget_text_icl clearfix" style={{padding:"20px", backgroundColor: "#1b1e22", float: "left"}}>
                                <h4 style={{color: "#7EDC6F"}}>Socials</h4>
                                <div className="textwidget">
                                    <div className="menu-footer-content widget_nav_menu_findus">
                                        <ul className="footer-menu">
                                            <li>
                                                <a className="SocialC" href="https://t.me/swollet ">Telegram</a>
                                            </li>
                                            <li>
                                                <a className="SocialC" href="https://discord.gg/YwJFgSZ6zE ">Discord</a>
                                            </li>
                                            <li>
                                                <a className="SocialC" href="https://www.instagram.com/teamswollet/ ">Instagram</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
    
                    <a href="#toplink" id="toplink" style={{display: "inline"}}><i className="fa fa-chevron-up fa-2x" aria-hidden="true"></i></a>

                </footer>

            </div>
            <footer className="footer">
                <div className="footer-wrapper">
                    <p className="large">©Swollet. All rights reserved</p>
                    <div className="social">
                        <a href="https://discord.gg/YwJFgSZ6zE">
                            <img src={discord} alt=""/>
                        </a>
                        <a href="https://www.facebook.com/swolletapp">
                            <img src={facebook} alt=""/>
                        </a>
                        <a href="https://www.instagram.com/teamswollet/">
                            <img src={instagram} alt=""/>
                        </a>
                        <a href="https://www.linkedin.com/company/swollet">
                            <img src={ln} alt=""/>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    </div>
  )
}

export default Business;