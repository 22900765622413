import React, {useEffect, useState} from 'react';
import { useUserContext } from '../hooks/useUserContext';
import { useLogOut } from "../hooks/useLogOut";
import "./Login.css";
import "./Profile.css";
import "./Swollet.css";
import arrow from '../images/arrow.svg';
import { useNavigate } from "react-router-dom";
import logo from '../images/logo.svg';
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useAuthContext } from '../hooks/useAuthContext';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react';

const Profile = () => {
    const { state: { userData } } = useUserContext();
    const { state: { user } } = useAuthContext();
    const navigate = useNavigate();
    const { logout } = useLogOut();
    const [isMobile, setIsMobile] = useState(false);

    const handleSignOut = async () => {
        try {
            await logout();
            navigate("/login")
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
      
          // Initial check
        updateIsMobile();
      
          // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);
      
          // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };

    }, [user]);


    return (
        <div>
            <div className="wrapper register-back">
                <div className="content">

                <header className="header">
                        <div className="header-wrapper ">
                            <a href="/" className="logo">
                                <img src={logo}  alt=""/>
                            </a>

                            {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/home")}}>Home</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/courses")}}>Courses</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/profile")}}>Profile</MenuItem>
                                        <MenuItem className="button btn-primary">Logout</MenuItem>
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <button className="button btn-primary">Messages</button>
                                    <button onClick={handleSignOut} className="button btn-primary">
                                        Logout
                                    </button>
                                </div>
                            )}

                        </div>
                </header>

                <section className="hero">
                    <div className="hide">
                        <Sidebar/>
                    </div>

                    <h1 className="centered-container">Profile {userData?.userName}</h1>
                    <div className="login-content ">
                        <div className="form__group field input1" style={{ "marginBottom": '20px' }}>
                            <input
                                name="lang"
                                id="lang"
                                placeholder="greek"
                                className="form-field"
                                required
                                disabled
                            />
                            <label htmlFor="email"  className="form-label" >Change Language</label>
                            <button onClick={() => {navigate("/language")}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>

                        <div className="form__group field input1">
                            <input
                                name="email" 
                                id="email" 
                                placeholder={`${userData?.email}`}
                                type="email"
                                className="form-field"  
                                required
                                disabled
                            />
                            <label htmlFor="email" className="form-label">Email (disabled for now)</label>
                            <button onClick={() => {}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>

                        <div className="form__group field input1">
                            <input
                                name="info" 
                                id="info" 
                                placeholder="info" 
                                type="info"
                                className="form-field"  
                                required
                                disabled
                            />
                            <label htmlFor="email" className="form-label">Personal Information</label>
                            <button onClick={() => {navigate("/personalinfo")}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>

                        <div className="form__group field input1">
                            <input
                                name="info" 
                                id="info" 
                                placeholder={`${userData?.bio}`}
                                type="info"
                                className="form-field"  
                                required
                                disabled
                            />
                            <label htmlFor="email" className="form-label">Bio</label>
                            <button onClick={() => {navigate("/bio")}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>

                        <div className="form__group field input1">
                            <input
                                name="info" 
                                id="info" 
                                placeholder={`${userData?.userName}`}
                                type="info"
                                className="form-field"  
                                required
                                disabled
                            />
                            <label htmlFor="email" className="form-label">Username</label>
                            <button onClick={() => {navigate("/username")}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>

                        <div className="form__group field input1">
                            <input
                                name="info" 
                                id="info" 
                                placeholder={`${userData?.location}`}
                                type="info"
                                className="form-field"  
                                required
                                disabled
                            />
                            <label htmlFor="email" className="form-label">Location (disabled for now)</label>
                            <button onClick={() => {/*navigate("/location")*/}} className="button-arrow">
                                <img src={arrow} style={{ transform: "rotate(270deg)" }} alt=""/>
                            </button>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
    </div>
    )
}

export default Profile
