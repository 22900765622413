import React, { useState } from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { useUpdate } from "../hooks/useUpdate";
import { useAuthContext } from '../hooks/useAuthContext';
import { useGetUser } from "../hooks/useGetUser";

const Username = () => {
  const { state: { userData } } = useUserContext();
  const { dispatch } = useUserContext();
  const { state: { user } } = useAuthContext();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>();
  const { updateUser } = useUpdate();
  const { getUser } = useGetUser();

  const handleSave = async () => {

    const fieldToUpdate = "userName";
    const newValue = username;

    console.log("Before update: ", fieldToUpdate, " ", newValue);

    await updateUser(fieldToUpdate, newValue, user.stsTokenManager.accessToken);

    const userData = await getUser(user.stsTokenManager.accessToken);

    localStorage.setItem('userData', JSON.stringify(userData));
    dispatch({type: 'LOGIN', payload: userData})

    navigate("/profile")
  }

  return (
    <div>
        <div className="wrapper">
            <div className="content">
              <div className="login-title">Username </div>
              <div className="login-content">
                  <div className="form__group field input1">
                      <input
                          name="lang" 
                          id="lang" 
                          placeholder={`${userData?.userName}`}
                          onChange={(event) => setUsername(event.target.value)}
                          className="form-field"  
                          required
                      />
                      <label htmlFor="email"  className="form-label">Your current username</label>
                      
                  </div>
                  <div className="form__group field input1 centered-container">
                    <button className="button btn-primary">
                        Save
                    </button>
                  </div>
              </div>
              <div className="centered-container" style={{"marginTop": "70px"}}>
                    <button onClick={() => {navigate("/profile")}} className="button btn-primary" style={{"color": "black"}}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Username
