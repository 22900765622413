import React, { useState } from 'react';

export const useEncryption = () => {

    const encryptPassword = (password: string) => {
        try {
            const encodedPass = btoa(password);
            return encodedPass;
        } catch (error) {
            // Handle encoding error (e.g., invalid input)
            console.error('Encryption error:', error);
            return null;
        }
    };

    const decryptPassword = (passwordAsBase64: string) => {
        try {
            const decodedPass = atob(passwordAsBase64);
            return decodedPass;
        } catch (error) {
            // Handle decoding error (e.g., invalid input)
            console.error('Decryption error:', error);
            return null;
        }
    };
  
    return {
        encryptPassword,
        decryptPassword
    }
};