import React, { useState, useEffect } from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Login.css";
import "./Profile.css";
import arrow from '../images/arrow.svg';
import { useNavigate } from "react-router-dom";

const FillInfo = () => {
    const { state: { userData } } = useUserContext();
    const navigate = useNavigate();

    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="wrapper">
            <div className="content">
                {showMessage && (
                    <div className="success-message">
                        Well done
                    </div>
                )}

                <button onClick={() => {navigate("/profile")}}  className="button btn-primary">
                    Set up your account
                </button>
            </div>
        </div>
    )
}

export default FillInfo;