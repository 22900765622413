import React, { useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { Select } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import "../css/Courses.css";
import ButtonCourse from '../components/ButtonCourse';
import web3 from '../images/web3.png';

const Web3CourseContent: React.FC = () => {
    //const { user } = useAuthContext();
    const { state: { user } } = useAuthContext();

    useEffect(() => {
        console.log("User --> ", user);
    }, [])

    return (
        <div>
            <div className="centered-container">
                <img src={web3} alt="Logo" className="logo"/>
            </div>
            <div className="wrapper">
            <Accordion allowToggle className="courses">
                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                        Main
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">
                        <ButtonCourse name="01 How to open a Metamask wallet" url="web3/howtoopenametamaskwallet"/>

                        <ButtonCourse name="02 What is Web3.0" url="web3/whatisweb3"/>

                        <ButtonCourse name="03 Introduction to the Metaverse" url="web3/introtothemetaverse"/>

                        <ButtonCourse name="04 Virtual Reality (VR)" url="web3/virtualreal"/>

                        <ButtonCourse name="05 Augmented Reality (AR)" url="web3/augemReality"/>

                        <ButtonCourse name="06 Virtual Worlds" url="web3/virtualwolrds"/>

                        <ButtonCourse name="07 Persistent Virtual Spaces" url="web3/persistentvirtualspaces"/>

                        <ButtonCourse name="08 Convergence and the Future of the Metaverse" url="web3/convergenceandtheFutureoftheMetaverse"/>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="courses-item">
                        <h2>
                        <AccordionButton className="courses-item-button">
                            <Box as="span" flex='1' textAlign='left'>
                            NFTs
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} className="courses-item-panel">
                            <ButtonCourse name="01 What are NFTs" url="web3/whatareNFTs"/>
                        </AccordionPanel>
                </AccordionItem>
            </Accordion>
            </div>
        </div>
    )
}

export default Web3CourseContent;
