import React, { createContext, useContext, useState } from "react";
import AlertDialog from "../components/AlertDialog"; // Import the AlertDialog component

interface AlertContextProps {
  alertState: string;
  showAlert: (message: string) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<string>("");

  // Function to show an alert message
  const showAlert = (message: string) => {
    setState(message);
    setTimeout(() => {
      // Hide the alert after a certain duration (e.g., 5 seconds)
      setState("");
    }, 5000); // Hide after 5 seconds (adjust the duration as needed)
  };

  return (
    <AlertContext.Provider value={{ alertState: state, showAlert }}>
      {children}
      {state && <AlertDialog message={state} />}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlertContext must be used within an AlertProvider");
  }
  return context;
};

