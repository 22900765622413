import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button1 from '../components/Button1';
import ln from '../images/ln.svg';
import logo from '../images/logo.svg';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'


const PrivacyPolicy = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [textSlideIndex, setTextSlideIndex] = useState(1);
  const navigate = useNavigate();
  let interval: NodeJS.Timeout;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {

      const updateIsMobile = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };
  
      // Initial check
      updateIsMobile();
  
      // Event listener for window resize
      window.addEventListener('resize', updateIsMobile);

      return () => {
          clearInterval(interval);
          window.removeEventListener('resize', updateIsMobile);
      };

  }, []);

  return (
    <div>
      <div className="wrapper">
        <div className="content">
            <header className="header">
                <div className="header-wrapper">
                    <a href="/" className="logo">
                        <img src={logo}  alt=""/>
                    </a>
                    {isMobile ? (
                            // Render the dropdown menu for mobile screens
                            <div id="hamburger-menu">
                                <Menu>
                                <MenuButton as={Button} className="button btn-primary">
                                Actions
                                </MenuButton>
                                <MenuList>
                                    <MenuItem className="button btn-primary" onClick={() => {navigate("/business")}}>Business</MenuItem>
                                    {/*<MenuItem className="button btn-primary" onClick={() => {navigate("/login")}}>Login</MenuItem>*/}
                                </MenuList>
                                </Menu>
                            </div>
                        ) : (
                            // Render the buttons for larger screens
                            <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                <Button1 name="Business" url="business"/>
                                {/*<Button1 name="Login" url="login"/>*/}
                            </div>
                        )}
                </div>
            </header>
            <header className="header sticky">
                <div className="header-wrapper">
                    <a href="/" className="logo">
                        <img src={logo} alt=""/>
                    </a>
                    {/*<a href="/business"><button className="button btn-primary">For Business</button></a>*/}
                    <Button1 name="Business" url="business"/>
                    <Button1 name="Login" url="login"/>
                </div>
            </header>
        <section className="hero">
          <div className="container">
            <div className="hero-wrapper">
              <h1 className="h1 green-gradient">Privacy Policy<br /></h1>
            </div>

            <div className="about-wrapper">
              <div className="aos-init aos-animate">
                <h2 className="green-gradient" style={{marginTop: "50px"}}>Rules</h2>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>1. Introduction</h3>

                <p className="large">
                Welcome to Swollet! This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our mobile application. Your privacy and data security are of utmost importance to us. By using the Swollet mobile app, you consent to the practices described in this Privacy Policy.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>2. Information We Collect</h3>

                <p className="large">
                  <h5 className="green-gradient" style={{marginTop: "50px"}}>2.1. Information You Provide:</h5> We may collect personal information, such as your name, email address, and payment details, when you sign up, make purchases, or interact with our app.

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>2.2. Automatically Collected Information:</h5> We may collect device information, usage patterns, and analytics data to improve our services and user experience.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>3. How We Use Your Information</h3>
                <p className="large">          
                  We use your information for the following purposes:

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>3.1. Account Management:</h5> To create and manage your Swollet account.

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>3.2. Personalization:</h5> To tailor content and recommendations to your preferences.

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>3.3. Transactions:</h5> To process payments and deliver services you request.

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>3.4. Communication:</h5> To provide updates, respond to inquiries, and send relevant notifications.

                  <h5 className="green-gradient" style={{marginTop: "50px"}}>3.5. Analytics:</h5> To analyze app usage and improve our services.

                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>4. Data Sharing</h3>
                <p className="large">          
                We may share your information with trusted third parties for specific purposes, such as payment processing or customer support. We do not sell your personal information to third parties.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>5. Security</h3>
                <p className="large">
                  We employ industry-standard security measures to protect your data. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>6. Your Choices</h3>
                <p className="large">
                You can update or delete your account information within the app settings. You also have the right to access, rectify, or delete your personal data as permitted by applicable laws.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>7. Updates to Privacy Policy</h3>
                <p className="large">
                We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any significant changes via email or within the app.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>8. Contact Us</h3>
                <p className="large">
                  If you have any questions or concerns regarding your privacy or this Privacy Policy, please contact us at info@swollet.com.
                </p>

              
            </div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;