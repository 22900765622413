import React, { useState } from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { useUpdate } from "../hooks/useUpdate";
import { useAuthContext } from '../hooks/useAuthContext';
import { useGetUser } from "../hooks/useGetUser";

const Bio = () => {
  const { state: { userData } } = useUserContext();
  const { dispatch } = useUserContext();
  const { state: { user } } = useAuthContext();
  const navigate = useNavigate();
  const [bio, setBio] = useState<string>();
  const { updateUser } = useUpdate();
  const { getUser } = useGetUser();

  const handleSave = async () => {
    //const storedUserData = JSON.parse(localStorage.getItem('userData'));

    const fieldToUpdate = "bio";
    const newValue = bio;

    console.log("Before update: ", fieldToUpdate, " ", newValue);

    await updateUser(fieldToUpdate, newValue, user.stsTokenManager.accessToken);

    const userData = await getUser(user.stsTokenManager.accessToken);

    localStorage.setItem('userData', JSON.stringify(userData));
    dispatch({type: 'LOGIN', payload: userData})

    /*const userJson = localStorage.getItem('userData');
    const storedUserData = userJson ? JSON.parse(userJson) : null;
    
    if (storedUserData) {
      storedUserData.bio = bio;
      try {
        localStorage.setItem('userData', JSON.stringify(storedUserData));
        dispatch({type: 'LOGIN', payload: storedUserData})
      } catch (error) {
        // Handle localStorage error, e.g., it's full or unavailable
        console.error('Error saving to localStorage:', error);
      }
    }*/

    navigate("/profile")
  }

  return (
    <div>
        <div className="wrapper">
            <div className="content">
                <div className="login-title"> Bio </div>
                
                
                <div className="login-content" style={{"height": "40vh"}}>
                  <div className="form__group field input1">
                      <textarea
                        maxLength={1250} // Change from "1250" to 1250
                        rows={4} // Change from "4" to 4
                        cols={50} // Change from "50" to 50
                        placeholder="Write your bio here..."
                        onChange={(event) => setBio(event.target.value)}
                        className="bio"
                        style={{"height": "20vh"}}
                      />
                      <label htmlFor="email"  className="form-label" >Bio</label>
                      
                  </div>

                  <div className="form__group field input1 centered-container">
                    <button className="button btn-primary">
                        Save
                    </button>
                  </div>


                </div>
                <div className="centered-container" style={{"marginTop": "70px"}}>
                  <button onClick={() => {navigate("/profile")}} className="button btn-primary" style={{"color": "black"}}>
                      Cancel
                  </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Bio
