import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button1 from '../components/Button1';
import ln from '../images/ln.svg';
import logo from '../images/logo.svg';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

  

const UserAgreement = () => {

  const [slideIndex, setSlideIndex] = useState(1);
  const [textSlideIndex, setTextSlideIndex] = useState(1);
  const navigate = useNavigate();
  let interval: NodeJS.Timeout;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {

      const updateIsMobile = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };
  
      // Initial check
      updateIsMobile();
  
      // Event listener for window resize
      window.addEventListener('resize', updateIsMobile);

      return () => {
          clearInterval(interval);
          window.removeEventListener('resize', updateIsMobile);
      };

  }, []);

  return (
    <div>
        <div className="wrapper">
            <div className="content">
            <header className="header">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo}  alt=""/>
                        </a>
                        {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/business")}}>Business</MenuItem>
                                        {/*<MenuItem className="button btn-primary" onClick={() => {navigate("/login")}}>Login</MenuItem>*/}
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <Button1 name="Business" url="business"/>
                                    {/*<Button1 name="Login" url="login"/>*/}
                                </div>
                            )}
                    </div>
                </header>
                <header className="header sticky">
                    <div className="header-wrapper">
                        <a href="/" className="logo">
                            <img src={logo} alt=""/>
                        </a>
                        {/*<a href="/business"><button className="button btn-primary">For Business</button></a>*/}
                        <Button1 name="Business" url="business"/>
                        <Button1 name="Login" url="login"/>
                    </div>
                </header>

                <section className="hero">
                    <div className="container">
                      <div className="hero-wrapper">
                      <div className="aos-init aos-animate">
                        <h1 className="h1 green-gradient">User Agreement<br /></h1>
                        <p className="large">End User License Agreement (EULA) for Swollet Mobile App
                        </p>
                        <p className="large">
                          This End User License Agreement ("Agreement") is a legal agreement between you ("User" or "You") and Swollet ("Company," "We," or "Us") governing the use of the Swollet mobile application ("App").
                        </p>
                        </div>
                      </div>

                      <div className="about-wrapper">
                        <div className="aos-init aos-animate">
                            <h3 className="green-gradient" style={{marginTop: "50px"}}>1. Acceptance of Terms</h3>
                            <p className="large">
                              By downloading, installing, or using the Swollet App, You agree to be bound by the terms and conditions of this Agreement. If You do not agree to these terms, do not use the App.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>2. License Grant</h3>
                            <p className="large">
                              We grant You a limited, non-exclusive, non-transferable, revocable license to use the App solely for your personal, non-commercial use, subject to the terms of this Agreement.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>3. Restrictions</h3>
                            <p className="large">
                              You shall not:

                              Modify, reverse engineer, decompile, or disassemble the App.
                              Attempt to gain unauthorized access to the App or its related systems.
                              Use the App for any illegal, harmful, or malicious purposes.
                              Share or transfer your account or license to any third party.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>4. Intellectual Property</h3>
                            <p className="large">
                              The App, including all content, features, and functionality, is owned by Swollet and protected by copyright and other intellectual property laws. You may not reproduce, distribute, or create derivative works from the App.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>5. Privacy</h3>
                            <p className="large">
                              Your use of the App is also governed by our Privacy Policy, which can be found [link to Privacy Policy]. By using the App, You consent to the collection and use of your data as described in the Privacy Policy.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>6. Termination</h3>
                            <p className="large">
                              We reserve the right to terminate or suspend your license to use the App at any time, with or without cause. Upon termination, You must cease using the App and delete it from your device.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>7. Disclaimers</h3>
                            <p className="large">
                              The App is provided "as is" and "as available" without warranties of any kind, whether express or implied. We do not guarantee that the App will be error-free or uninterrupted.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>8. Limitation of Liability</h3>
                            <p className="large">
                              To the fullest extent permitted by applicable law, Swollet shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the App.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>9. Governing Law</h3>
                            <p className="large">
                              This Agreement is governed by and constructed in accordance with the laws of Ireland, without regard to its conflict of law principles.
                            </p>

                            <h3 className="green-gradient" style={{marginTop: "30px"}}>10. Contact Information</h3>
                            <p className="large">
                              For questions or concerns about this Agreement or the App, please contact us at info@swollet.xyz.
                            </p>
                            <p className="large">
                              By using the Swollet App, You acknowledge that You have read and understood this Agreement and agree to be bound by its terms.
                            </p>  
                        </div>
                      </div>
                    </div>
                </section>
                
            </div>
        </div>
    </div>
  )
}

export default UserAgreement