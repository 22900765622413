import React, {useEffect, useState} from 'react';
import { useCourse } from "../hooks/useCourse";
import { useRandom } from "../hooks/useRandom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import "./Course.css";
import { ChakraProvider } from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useGetUser } from "../hooks/useGetUser";
import { useUpdate } from "../hooks/useUpdate";

const Course: React.FC = () => {
  const { state: { user } } = useAuthContext();
  const { dispatch } = useUserContext();

  const { courseBody, course, isLoading } = useCourse();
  const { random, isLoading1 } = useRandom();
  const [courseData, setCourseData] = useState<any>();
  const [xp, setXP] = useState<number>(0);
  const [answer, setAnswer] = useState<any>(""); // set the answer of a specific question
  const [flag, setFlag] = useState<number>(-1); // flag for the content->questions->display of correct questions

  const [flagQuestions, setFlagQuestions] = useState<boolean>(false);
  const [answerQuestion, setAnswerQuestion] = useState<any>();

  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [correctFlag, setCorrectFlag] = useState<boolean>(false);
  const [correct, setCorrect] = useState<string | null>("");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // in which question i am now

  //const [courseId, setCourseId] = useState<string>("");

  const { id } = useParams<any>(); // for the parameters of the route (/:id)
  const { updateUser } = useUpdate();
  const { getUser } = useGetUser();

  // for the courses
  const [loading, setLoading] = useState(true);
  const [courseStatus, setCourseStatus] = useState<string>("");
  const [courseId, setCourseId] = useState(null);

  //
  const [courseData1, setCourseData1] = useState<CourseModel | null>(null);

  const navigate = useNavigate();

  // questions-answers
 // const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);


  
  interface CourseModel {
    //id: string | null;
    cover: Cover;
    creator: string;
    courseName: string;
    content: ContentText[]; // Use a more specific type if possible
    know: number;
    xp: number;
    category: string;
    subcategory: string | null;
    estimatedCompletionTime: string;
    nextRecommendedCourseName: string | null;
    like: number;
    dislike: number;
    //task?: string | null;
    orderNumber: number;
    requiredCourses: string[] | null;
    //format: CourseFormat;
    isLocked: boolean;
    tags: string[];
    isHidden: boolean;
    endOfCourseQuestions: EndOfCourseQuestion[] | null;

    //questions: Question[] | MissingWord | SoundQuestion | FormSentence; // Implement the questions property



    //coverImage: string | null; // Implement the coverImage property

    //text: TextSnippet[]; // Implement the text property
    // Define other methods and properties as needed
  }


  // text snippet
  interface TextSnippet {
    text: string;
    playing: boolean;
    played: boolean;
  }


  // cover
  interface Cover {
    title: string;
    text: string;
    imageUrl?: string | null;
    media?: MediaModel | null;
  }

  // content
  interface ContentText {
    title: string | null;
    text: string;
    contentType: CourseContentType;
    highlightedText?: HighlightedText | null;
  }

  interface HighlightedText{
    highlightedText: string;
    description: string;
  }

  enum CourseContentType {
    Text,
    Question,
    AnimatedImage,
    ImageUrl,
    PreviewUrl,
    YouTubeVideoID,
    Media,
    OpinionLoop,
    Carousel1,
  }

  interface ContentCarouselSlider {
    title: string | null;
    items: CarouselItemModel[];
    contentType: CourseContentType;
  }

  interface CarouselItemModel {
    title: string;
    imageUrl: string;
    description: string;
  }

  interface ContentOpinionLoop{
    title: string | null;
    texts: string[];
    contentType: CourseContentType;
  }

  interface ContentAnimatedImage {
    title?: string;
    imageUrl: string;
    text?: string;
    contentType: CourseContentType;
    duration?: number;
  }

  interface ContentImageUrl{
    title?: string;
    imageUrl: string;
    contentType: CourseContentType;
  }

  interface ContentImageUrl {
    title?: string;
    imageUrl: string;
    contentType: CourseContentType;
  }

  interface ContentPreviewUrl {
    title?: string;
    previewUrl: string;
    contentType: CourseContentType;
  }

  interface ContentYoutubeVideoID {
    title?: string;
    youTubeVideoID: string;
    contentType: CourseContentType ;
  }

  interface ContentMedia{
    title?: string;
    media: MediaModel;
    contentType: CourseContentType;
  }

  // media model
  interface MediaModel {
    filename: string;
    fileType: number;
    id: string;
    url?: string | null;
  }

  // question
  interface Question {
    question: string;
    answers: Answer[];
    contentType: CourseContentType;
    multipleAnswerable: boolean;
  }

  // answer of question
  interface Answer{
    answer: string;
    result: string;
    correct: boolean;
  }


  // endOfCourseQuestions
  interface EndOfCourseQuestion {
    missingWord?: MissingWord;
    soundQuestion?: SoundQuestion;
    selectCorrectAnswer?: Question[];
    formSentence?: FormSentence;
    questionSet?: Question[];
  }

  interface MissingWord {
    question: Question[]; // this part is the same with the selectCorrectAnswer and questionSet
    missingWord: string;
  }
  
  interface SoundQuestion {
    question: Question[];
    soundURL: string;
  }
  
  interface FormSentence {
    question: string[];
    sentenceToBeFormed: string;
  }

  const courseFromFirestore = (data: any): CourseModel => ({
    //id: "123",
    creator: data.creator,
    courseName: data.courseName,
    content: data.content,
    know: data.know,
    xp: data.xp,
    category: data.category,
    subcategory: data.subcategory,
    estimatedCompletionTime: data.estimatedCompletionTime,
    nextRecommendedCourseName: data.nextRecommendedCourseName,
    like: data.like ?? 0,
    dislike: data.dislike ?? 0,
    //task: data.task,
    orderNumber: data.orderNumber,
    requiredCourses: data.requiredCourses,
    //format: data.format,
    isLocked: data.isLocked ?? false,
    tags: data.tags,
    cover: data.cover,
    isHidden: data.isHidden,
    endOfCourseQuestions: (data.endOfCourseQuestions || []).map((questionData: any): EndOfCourseQuestion => {
      if (questionData.missingWord) {
        return {
          missingWord: {
            question: questionData.missingWord.question,
            missingWord: questionData.missingWord.missingWord,
          },
        };
      } else if (questionData.soundQuestion) {
        return {
          soundQuestion: {
            question: questionData.soundQuestion.question,
            soundURL: questionData.soundQuestion.soundURL,
          },
        };
      } else if (questionData.selectCorrectAnswer) {
        // Normalize to an array of objects
        const selectCorrectAnswerArray = Array.isArray(questionData.selectCorrectAnswer)
          ? questionData.selectCorrectAnswer
          : [questionData.selectCorrectAnswer];
  
        return {
          selectCorrectAnswer: selectCorrectAnswerArray.map((q: any): Question => ({
            question: q.question,
            answers: q.answers,
            contentType: q.contentType,
            multipleAnswerable: q.multipleAnswerable,
          })),
        };
      } else if (questionData.formSentence) {
        return {
          formSentence: {
            question: questionData.formSentence.question,
            sentenceToBeFormed: questionData.formSentence.sentenceToBeFormed,
          },
        };
      } else if (questionData.questionSet) {
        return {
          questionSet: questionData.questionSet.map((q: any): Question => ({
            question: q.question,
            answers: q.answers,
            contentType: q.contentType,
            multipleAnswerable: q.multipleAnswerable,
          })),
        };
      } else {
        // Handle other cases or add appropriate error handling here
        return {}; // Empty object as a placeholder
      }
    }),
  

  });

  /*function parseEndOfCourseQuestion(data: any): EndOfCourseQuestion {
    return {
      missingWord: data.missingWord ? MissingWord.fromJson(data.missingWord) : undefined,
      soundQuestion: data.soundQuestion ? SoundQuestion.fromJson(data.soundQuestion) : undefined,
      selectCorrectAnswer: data.selectCorrectAnswer ? [Question.fromJson(data.selectCorrectAnswer)] : undefined,
      formSentence: data.formSentence ? FormSentence.fromJson(data.formSentence) : undefined,
      questionSet: data.questionSet ? data.questionSet.map((item: any) => Question.fromJson(item)) : undefined,
    };
  }*/


  useEffect(() => {
    const fetchData = async () => {
      const courseContext = await course(user.stsTokenManager.accessToken, id);
      const processedCourseData = courseFromFirestore(courseContext[0]);
      const idToken = user.stsTokenManager.accessToken;

      // get the course
      await random(idToken, id);

      setCourseData(courseContext);
      setCourseData1(processedCourseData)

      console.log("courseData NEW: ", processedCourseData);
      console.log("courseData NEW: ", courseData1);
      console.log("courseContext: ", courseContext);
      console.log("introToBitcoin: ", courseData);
    };

    const fetchCourseId = async () => {
      const idToken = user.stsTokenManager.accessToken;

      console.log("idToken: ", idToken);
      console.log("id is: ", id);
      const courseNickname = id;

      try{

        const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/getcourseid/${courseNickname}?idToken=${idToken}`, {
          method: 'GET',
        });

        const json = await response.json();

        console.log("json: ", json);

        const courseId = json.courseid;

        const response1 = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/iscompleted/${courseId}?idToken=${idToken}`, {
          method: 'GET',
        });

        const json1 = await response1.json();

        if ('started' in json1 && 'ended' in json1) {
          setCourseStatus(json1.started);
        } else if ('status' in json1 && json1.status === 'datesNotAvailable') {
          setCourseStatus('datesNotAvailable');
        }

        setLoading(false); // Data loading is complete

      } catch (error) {
        console.log("Error in fetchCourseId: ", error);
        setLoading(false); // Set loading to false even in case of an error
      }

    }

    /*const fetchIsCompleted = async () => {
      const idToken = user.stsTokenManager.accessToken;

      try{

        const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/iscompleted/${courseId}?idToken=${idToken}`, {
          method: 'GET',
        });

        const json = await response.json();

        console.log("Is completed: ", json);
      } catch (error) {
        console.log("Error in fetchCourseId: ", error);
      }

    }*/

    fetchData();

    fetchCourseId();

    //fetchIsCompleted();

    console.log("courseId: ", courseId);

  }, [])


  const handleClick = () =>{
    /*let questions = courseData.map((item: any) =>
      item.endOfCourseQuestions.map((questions: any) => questions)
    );*/

    //console.log("questions: ", questions);


    setFlag(flag+1);

    if (courseData1?.endOfCourseQuestions?.length === 0) {
      setFlag(2);
    }
  }

  const handleAnswer = (correct: boolean) =>{
    if(correct == true){
      setXP(xp + 1);
      setAnswer("Correct");
      console.log("Correct!");
    } else {
      console.log("False!");
      setAnswer("False!");
    }
  }

  const handleContinue = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setFlagQuestions(false);
  };

  // handle Check
  const handleCheck = () => {
    console.log("The selected answer is: ", answerQuestion);
    setFlagQuestions(true); // to show if the answer is correct
    if(answerQuestion.correct === true){
      setCorrectAnswers(correctAnswers + 1);  // the answer is correct, so increase by 1 the correctAnswers    
      setCorrectFlag(true);                   // display if the answer is correct or not
      setCorrect("Correct");
       
      console.log("FlagQuestions: ", flagQuestions);
    } else {
      setCorrect("False")
    }
  }

  const getAnswer = (answer: any) => {
    console.log("I have ", correctAnswers, " correct answers!");
    setAnswerQuestion(answer);
    setCorrectFlag(false);      
    console.log("The selected answer is: ", answerQuestion);
  }

  const handleClickBack = async () => {
    const xpCourse = courseData1?.xp;

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // when malakas petridis do some APIs uncomment this
    if( (correctAnswers*100)/currentQuestionIndex > 70 ) {

      const userJson = localStorage.getItem('userData');
      const storedUserData = userJson ? JSON.parse(userJson) : null;

      /*if (storedUserData) {
        storedUserData.xp = storedUserData.xp + xpCourse;

        const fieldToUpdate = "xp";
        const newValue = storedUserData.xp;

        try {

          await updateUser(fieldToUpdate, newValue, user.stsTokenManager.accessToken);

          localStorage.setItem('userData', JSON.stringify(storedUserData));
          dispatch({type: 'LOGIN', payload: storedUserData});

        } catch (error) {
          // Handle localStorage error, e.g., it's full or unavailable
          console.error('Error saving to localStorage:', error);
        }
      }*/
      // thelei kapoia APIs  
    }

    navigate("/courses")
  }

  return (
    <div>
      {courseData1 && courseData.length > 0 && loading === false ? (

        <div>

          <div style={{display: flag === -1 ? "block" : "none"}}>

            <div className="centered-container">
              {courseStatus === "datesNotAvailable" ? (
                <div className="center-hor">
                  <div>You have not started this course. Do you want to start it?</div>
                  <div className="center-but">
                    <button className="button btn-primary" onClick={() => handleClick()}>Start it!</button>
                  </div>
                </div>
              ) : (
                <div className="center-hor">
                  <div style={{marginBottom: "20px"}}>You have started this course. Do you want to continue it?</div>
                  <div className="center-but">
                    <button className="button btn-primary" onClick={() => handleClick()}>Continue it!</button>
                  </div>
                </div>
              )}
            </div>

          </div>

          <div style={{display: flag === 0 ? "block" : "none"}}>

            <div className="centered-container">
              <img src={courseData1?.cover?.media?.url || ''} alt="Logo" className="logo"/>
              <h1>{courseData1?.cover?.title}</h1>
            </div>
            
            {courseData1?.content?.map((item:any) => (
              <ul className="centered-container">
                  <li className="crypto-content">{item.text}</li>
              </ul>
            ))}

            <div className="centered-container"> 
              <button className="button btn-primary" style={{marginBottom: "50px", marginTop: "20px"}} onClick={() => handleClick()}>Complete</button>
            </div>

          </div>


          {courseData1?.endOfCourseQuestions && courseData1.endOfCourseQuestions.length > 0 && (
            <div style={{display: flag === 1 ? "block" : "none"}}>
              <div className="center-but">
                <h1 style={{marginBottom: "50px", marginTop: "50px"}}> Questions </h1>
              </div>

              {courseData1?.endOfCourseQuestions?.map((item: EndOfCourseQuestion, index: number) => (
                  <ul key={index}>
                    {/*<li className="crypto-content">*/}
                      
                      {item?.missingWord && ( 
                        <div className="centered-container" >
                          <li className="crypto-content" style={{ display: index === currentQuestionIndex ? "block" : "none" }}>
                            Missing Word Question: {item.missingWord?.question[0]?.question}
                            <ul>
                              {item.missingWord?.question[0]?.answers.map((answer: Answer, aIndex: number) => (
                                <li key={aIndex}>
                                  <button className={`buttonBlock ${answerQuestion === answer ? 'green' : ''}`} onClick={() => getAnswer(answer)}>
                                    {aIndex} {answer.answer}
                                  </button>
                                </li>
                              ))}
                            </ul>
                            <div style={{ display: flagQuestions ? "block" : "none" }}>
                              <h1 className={correct === "Correct" ? "buttonBlock-green" : correct === "False" ? "buttonBlock-red" : ""}>{correct}</h1>
                            </div>
                            {flagQuestions}
                            <button className="buttonBlock" onClick={() => handleCheck()}>Check</button>
                            <button className="buttonBlock" onClick={() => handleContinue()}>Continue</button>
                          </li>
                        </div>
                      )}

                      {item?.soundQuestion && (
                        <div className="centered-container">
                          <li className="crypto-content" style={{ display: index === currentQuestionIndex ? "block" : "none" }}>
                            Sound Word Question: {item.soundQuestion?.question[0]?.question}
                            <ul>
                              {item.soundQuestion?.question[0]?.answers.map((answer: Answer, aIndex: number) => (
                                <li key={aIndex}>
                                  <button className={`buttonBlock ${answerQuestion === answer ? 'green' : ''}`} onClick={() => getAnswer(answer)}>
                                    {aIndex} {answer.answer}
                                  </button>
                                </li>
                              ))}
                            </ul>
                            <div style={{ display: flagQuestions ? "block" : "none" }}>
                              <h1 className={correct === "Correct" ? "buttonBlock-green" : correct === "False" ? "buttonBlock-red" : ""}>{correct}</h1>
                            </div>
                            {flagQuestions}
                            <button className="buttonBlock" onClick={() => handleCheck()}>Check</button>
                            <button className="buttonBlock" onClick={() => handleContinue()}>Continue</button>
                          </li>
                        
                          <button className="buttonBlock" onClick={() => {setFlag(flag+1)}}>See correct answers</button>
                        </div>
                      )}
                      

                      {item?.selectCorrectAnswer && (
                        <div>
                          <li>
                            
                            <ul className="centered-container">
                              {item.selectCorrectAnswer.map((question, qIndex) => {
                                // Define a separate variable to hold the index
                                

                                return (
                                  <li key={qIndex} className="crypto-content" style={{ display: index === currentQuestionIndex ? "block" : "none" }}>
                                    Question {index}: {question.question}
                                    <ul>
                                      {question.answers.map((answer, aIndex) => (
                                        <li key={aIndex}>
                                          <button className={`buttonBlock ${answerQuestion === answer ? 'green' : ''}`} onClick={() => getAnswer(answer)}>
                                            {aIndex} {answer.answer}
                                          </button>
                                        </li>
                                      ))}
                                    </ul>
                                    <div key={qIndex} style={{ display: flagQuestions ? "block" : "none" }}>
                                      <h1 className={correct === "Correct" ? "buttonBlock-green" : correct === "False" ? "buttonBlock-red" : ""}>{correct}</h1>
                                    </div>
                                    {flagQuestions}
                                    <div className="centered-container-button" style={{marginBottom: "20px", marginTop: "20px"}}>
                                      <button className="button btn-primary" onClick={() => handleCheck()}>Check</button>
                                      <button className="button btn-primary" onClick={() => handleContinue()}>Continue</button>
                                    </div>
                                  </li>
                                  
                                );
                              })}
                            </ul>


                          </li>

                          {courseData1?.endOfCourseQuestions?.length === currentQuestionIndex && (
                            <div className="center-but">
                              <button className="buttonBlock" onClick={() => {setFlag(flag+1)}}>See correct answers</button>
                            </div>
                          )}
                        </div>
                      )}
                      
                      {item?.formSentence && (
                        <li className="crypto-content">
                          {item.formSentence.question} 
                          <input />  
                        </li>
                      )}
                      
                      {item?.questionSet && (
                        <div>
                          <li>
                            
                            <ul className="centered-container">
                              {item.questionSet.map((question, qIndex) => (
                                <li key={qIndex} className="crypto-content" style={{ display: qIndex === currentQuestionIndex ? "block" : "none" }}>
                                  <div className="center-but" style={{marginBottom: "20px", marginTop: "10px"}}>
                                    {question.question}
                                  </div>
                                  <ul className="questions-container">
                                    {question.answers.map((answer, aIndex) => (
                                      <li key={aIndex}><button className={`buttonBlock ${answerQuestion === answer ? 'green' : ''}`} onClick={() => getAnswer(answer)}>{answer.answer}</button></li>
                                    ))}
                                  </ul>
                                  <div key={qIndex} style={{ display: flagQuestions ? "block" : "none" }}>
                                    <h1 className={correct === "Correct" ? "buttonBlock-green" : correct === "False" ? "buttonBlock-red" : ""}>{correct}</h1>
                                  </div>

                                  <div className="centered-container-button" style={{marginBottom: "20px", marginTop: "20px"}}>
                                    <button className="button btn-primary" onClick={() => handleCheck()}>Check</button>
                                    <button className="button btn-primary" onClick={() => handleContinue()}>Continue</button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <div className="center-but">
                            <button className="buttonBlock" onClick={() => {setFlag(flag+1)}}>See correct answers</button>
                          </div>
                        </div>
                        
                      )}

                    {/* You can add similar blocks for other question types */}
                  
                    
                  </ul>
                ))}

                
            </div>
          )}

          <div style={{marginTop: "50px", display: flag === 2 ? "block" : "none"}}>
            <div className="center-but">
              You had: {correctAnswers} correct Answers
            </div>
            <div className="center-but">
              <button className="buttonBlock" onClick={handleClickBack}>Go back to courses</button>
            </div>
          </div>

        </div>

      ) : (
        <p>Loading...</p>
      )}

    </div>
  )
}

export default Course