import React, {useEffect} from 'react';
import { Select } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import { useAuthContext } from '../hooks/useAuthContext';
import "../css/Courses.css";
import crypto from '../images/crypto.png';
import ButtonCourse from '../components/ButtonCourse';

const CryptoCourseContent: React.FC = () => {
    //const { user } = useAuthContext();
    const { state: { user } } = useAuthContext();
    
    useEffect(() => {
        console.log("User --> ", user);
    }, [])

    return (
    <div>
        <div className="centered-container">
            <img src={crypto} alt="Logo" className="logo"/>
        </div>
        <div className="wrapper">
            <Accordion allowToggle className="courses">
                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                            Crypto 101
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">

                        <ButtonCourse name="01 Blockchain Basics" url="crypto/blockchainbasics"/>

                        <ButtonCourse name="02 What is crypto?" url="crypto/whatiscrtypto"/>
                        
                        <ButtonCourse name="03 Introduction to Bitcoin" url="crypto/introToBitcoin"/>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                        Main
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">

                        <ButtonCourse name="01 Cryptography" url="crypto/cryptography"/>

                        <ButtonCourse name="02 Consensus" url="crypto/consensus"/>

                        <ButtonCourse name="03 Bonus: Real life Blockchain applications in different industries" url="crypto/reallifeblockchainapplicationsindifferentindustries"/>

                        <ButtonCourse name="04 Blockchain Limitations and Challenges" url="crypto/blockchainlimitationsandchallenges"/>

                        <ButtonCourse name="05 Introduction to Cryptocurrency Trading" url="crypto/introToCryptoTrad"/>

                        <ButtonCourse name="06 Introduction to XRP" url="crypto/IntroToXRP"/>

                        <ButtonCourse name="07 Introduction to Binance" url="crypto/IntroToBinance"/>

                        <ButtonCourse name="08 Introduction to Ethereum" url="crypto/IntroToEthereum"/>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                        Smart Contracts
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">
                        <ButtonCourse name="01 Smart Contracts" url="crypto/smartcontracts"/>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                        Defi
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">
                        <ButtonCourse name="01 Introduction to DeFi" url="crypto/introductiontoDeFi"/>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    </div>
  )
}

export default CryptoCourseContent
