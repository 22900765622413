import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useUserContext } from './useUserContext';
import { UserModel } from '../context/UserContext';
import {
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    OAuthProvider,
    onAuthStateChanged,
    isSignInWithEmailLink, 
    signInWithEmailLink,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../pages/Firebase';
import { useCreateUser } from "./useCreateUser";
import { useGetUser } from "../hooks/useGetUser";

interface LoginMethods {
    googleSignIn: () => Promise<void>;
    appleSignIn: () => Promise<void>;
    emailSignIn: (email: string, password: string) => Promise<void>;
    setUpRecaptha: (phone: string) => Promise<void>;
}

interface CustomUser {
    accessToken: string;
  }

export const useLogin = (): LoginMethods =>{
    //const { User } = auth;
    //const [error, setError] = useState(null);
    //const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();
    const { state: { user } } = useAuthContext();
    const { dispatch: userDispatch } = useUserContext();
    const { createUser } = useCreateUser();
    const { getUser } = useGetUser();

    const googleSignIn = async () => {

        const provider = new GoogleAuthProvider();
    
        // Optional: Set additional custom parameters for the provider
        // For example, to specify which Google accounts to allow:
        provider.setCustomParameters({ prompt: 'select_account' });
    
        try {
            const result = await signInWithPopup(auth, provider);

            let accessToken = (result as any).user.accessToken;
                
            //const accessToken  = result?.user?.stsTokenManager?.accessToken;

            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!AccessToken: ", accessToken);
            //console.log("result: ", result);
            //console.log("result.user: ", result.user);

            //const { stsTokenManager } = result.user;

            //const { user.accessToken } = result;

            //const accessToken = user.accessToken;

            //console.log("user in useLogin.tsx: ", user);

            //console.log("stsTokenManager: ", stsTokenManager);

            const userData = await getUser(accessToken);

            //console.log("userData from useLogin.tsx: ", userData);

            // user has authentication info ( the data from dispatch({type: 'LOGIN', payload: result.user}))
            localStorage.setItem('user', JSON.stringify(result.user));

            // userData has personal information about every user info 
            // ( the data from  userDispatch({ type: 'LOGIN', payload: userData });)
            localStorage.setItem('userData', JSON.stringify(userData));

            //AuthContext
            dispatch({type: 'LOGIN', payload: result.user})

            // Dispatch the LOGIN action with the userData
            // UserContext
            userDispatch({ type: 'LOGIN', payload: userData });
            // You can access the user object from the result if needed.
        } catch (error: any) {
            if (error.code === 'account-exists-with-different-credential') {
                // Handle the case where the user has an existing account with a different provider.
                // You may want to show a message to the user or provide options to merge accounts.
                console.error('Account exists with different credential:', error);
            } else {
                console.error('Google Sign-In Error:', error);
            }
        }
    };

    const appleSignIn = async () => {

        const provider = new OAuthProvider("apple.com");

        provider.setCustomParameters({
            // You can add additional scopes or parameters here if needed
            // For example, scopes: 'email',
        });

        try{
            const result = await signInWithPopup(auth, provider);
            //setUser(result.user);
            localStorage.setItem('user', JSON.stringify(result.user));

            dispatch({type: 'LOGIN', payload: result.user});

            console.log('User signed in with Apple:', result.user);
        } catch (error) {
            console.error('Apple Sign-In Error:', error);
        };
    };

    const emailSignIn = async (email: string, password: string) => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);

            //const { accessToken } = result.user;
            let accessToken = (result as any).user.accessToken;

            const userData = await getUser(accessToken);

            localStorage.setItem('user', JSON.stringify(result.user));
            localStorage.setItem('userData', JSON.stringify(userData));

            dispatch({type: 'LOGIN', payload: result.user});
            userDispatch({ type: 'LOGIN', payload: userData });

        } catch (error: any) {
            if(error.code === 'auth/wrong-password'){
                console.error('Account exists with different credential, wrong password.', error);
            } else {
                console.error('Google Sign-In Error:', error);
            }
        }
    };

    const setUpRecaptha = async (phone: string) => {
        console.log(auth);
        try{
            const recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {},
                auth
            );

            recaptchaVerifier.render();
            const result = await signInWithPhoneNumber(auth, phone, recaptchaVerifier);

            console.log("setUpRecaptha: ", result);

            return result;
        
        } catch(error){
            console.log(error);
        }
    }

    return { googleSignIn, appleSignIn, emailSignIn, setUpRecaptha } as LoginMethods;
}