import { createContext, useContext, useEffect, useReducer } from 'react';
import { auth } from '../pages/Firebase';
import { Timestamp } from 'firebase/firestore';

export interface UserModel {
  uid?: string | null;
  password?: string | null;
  userName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  personalInfo?: PersonalInfo | null;
  bio?: string | null;
  imageUrl?: string | null;
  friends?: string[] | null;
  blockList?: string[] | null;
  location?: UserLocation | null;
  creationTime?: Timestamp | null; // Date | null
  lastSignInTime?: Timestamp | null; // Date | null
  active?: boolean | null;
  accountValue?: number | null;
  know?: number | null;
  xp?: number | null;
  myGoals?: string[] | null;
  mySkills?: string[] | null;
  faceID?: boolean | null;
  admin?: boolean | null;
  deviceToken?: string | null;
  isiOS?: boolean | null;
  premium?: boolean | null;
}

export interface PersonalInfo {
    firstName: string | null;
    middleName?: string | null;
    lastName: string | null;
    birthDate: Date | null;
}

export interface UserLocation {
    address: string;
    geoPoint: string;
}

interface UserState {
  userData: UserModel | null;
}

type UserAction = {
  type: 'LOGIN' | 'LOGOUT';
  payload?: UserModel; // Update payload type to UserModel
};

const initialState: UserState = {
  userData: null, // Provide a default value of null
};

export const UserContext = createContext<{ state: UserState; dispatch: React.Dispatch<UserAction> }>({
  state: initialState,
  dispatch: () => {},
});

export const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.payload || null }; // Use null as a fallback
    case 'LOGOUT':
      return { ...state, userData: null };
    default:
      return state;
  }
};

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const userJson = localStorage.getItem('userData');

    console.log("user personal Data: ", userJson)

    if (userJson !== null) {
        const userData = JSON.parse(userJson);
  
        if (userData) {
          dispatch({ type: 'LOGIN', payload: userData });
        }
    }
  }, []);

  return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};