import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useRandom = () => {
    const [isLoading1, setIsLoading1] = useState(false);
  
    const random = async (idToken: string, courseName: any) => {
      setIsLoading1(true);

      console.log("idToken is: ", idToken);
  
      try {
        const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/course/${courseName}?idToken=${idToken}`);
  
        // If the API call is successful, update the allCourses state
        if (response.ok) {
            console.log("HEYYYYY IS OK!");
            const json = await response.json();
            console.log("response from random hook: ", response);
            console.log("json from random hook: ", json);
        }
  
        setIsLoading1(false);
      } catch (error) {
            console.log("FALSEEEE");
            setIsLoading1(false);
        // Handle error if needed
      }
    };

    return { random, isLoading1 };
  };