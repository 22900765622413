import React from 'react';
import Sidebar from "../components/Sidebar";

const Test = () => {
  return (
    <div>
      <Sidebar />
    </div>
  )
}

export default Test
