import React, { useEffect } from 'react';
import "./Register.css";
import Button1 from "../components/Button1";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useRegister } from "../hooks/useRegister";

const google = require('../images/google.png');
const apple = require('../images/apple.png');
const email = require('../images/email.png');
const phone = require('../images/phone-icon.png');

const Register: React.FC = () => {
  const navigate = useNavigate();
  //const { user } = useAuthContext();
  const { state: { user } } = useAuthContext();

  const { googleRegister, appleRegister, emailRegister } = useRegister();

  function handleClickEmail(): void {
    navigate("/register/email");
  }

  function handleClickPhone(): void {
    navigate("/register/phone");
  }

  function handleClickLogin(): void {
    navigate("/login");
  }

  const handleGoogleRegister = async () => {
    try {
      await googleRegister();
      if(user != null) {
        navigate("/fill");
      }
      console.log("user: ", user );
    } catch (error) {
      console.log(error);
    }
  };
  
  const handeleAppleRegister = async () => {
    try {
      await appleRegister();
      if(user != null) {
        navigate("/fill");
      }
      console.log("user: ", user );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /*if (user != null) {
      navigate("/home");
    }
    else{
      console.log("user is: " , null)
    }*/
  }, [user]);



  return (
    <div>

      <div className="wrapper register-back">
          <div className="content">
            <div className="register-title">Register </div>
            <div className="register-content">
              <div className="register-with">Register with:</div>
              <div className="register-with-btn" >
                <div className="inside-btn">
                  <img src={google} alt="" className="img1" onClick={handleGoogleRegister}/>
                  Google
                </div>
              </div>
              <div className="register-with-btn">
                <div className="inside-btn">
                  <img src={apple} alt="" className="img2" onClick={handeleAppleRegister}/>
                  Apple
                </div>
              </div>
              <div className="register-with-btn envel">
                <div className="inside-btn" onClick={handleClickEmail} >
                  <img src={email} alt="" className="img3"/>
                  Email
                </div>
              </div>
              <div className="register-with-btn">
                <div className="inside-btn" onClick={handleClickPhone} >
                  <img src={phone} alt="" className="img4"/>
                  Phone
                </div>
              </div>
            </div>
            <div className="have-account">
              <div className="a1">Do you have an account?</div>
              <div className="button-account a1" onClick={handleClickLogin}><Button1 name="Login" url="login"/></div>
            </div>

          </div>
      </div>
    </div>
  )
}

export default Register;
