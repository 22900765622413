import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useCourses = () => {
    const [allCourses, setAllCourses] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
  
    // idToken is the result.user.stsTokenManager.accessToken !! really important
    // this token is sended to the backend in order to take the information
    const courses = async (idToken: string) => {
      setIsLoading(true);

      console.log("idToken is: ", idToken);
  
      try {
        const response = await fetch('/courses', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // SOS when we send the body like here
          },
          body: JSON.stringify({ idToken }),
        });
  
        // If the API call is successful, update the allCourses state
        if (response.ok) {

            console.log("HEYYYYY IS OK!");
            const json = await response.json();

            console.log("response: ", response);
            console.log("json: ", json);

            setAllCourses(json);

            

            console.log("All courses: ", allCourses);
        }
  
        setIsLoading(false);
      } catch (error) {
            console.log("FALSEEEE");
            setIsLoading(false);
        // Handle error if needed
      }
    };
  
    return { allCourses, courses, isLoading };
  };
