import React from 'react';
import { useState } from "react";

export const useCreateUser = () => {

    const createUser = async (idToken: string, userData: any) => {

        console.log("We are into useCreateUser and the userData is: ", userData)

        try{

            const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/createuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // SOS when we send the body like here
                },
                body: JSON.stringify({
                    uid: userData.uid,
                    password: userData.password,
                    userName: userData.userName,
                    phoneNumber: userData.phoneNumber,
                    email: userData.email,
                    personalInfo: userData.personalInfo,
                    bio: userData.bio,
                    imageUrl: userData.imageUrl,
                    friends: userData.friends,
                    blockList: userData.blockList,
                    location: userData.location,
                    creationTime: userData.creationTime,
                    lastSignInTime: userData.lastSignInTime,
                    active: userData.active,
                    accountValue: userData.accountValue,
                    know: userData.know,
                    xp: userData.xp,
                    myGoals: userData.myGoals,
                    mySkills: userData.mySkills,
                    faceID: userData.faceID,
                    admin: userData.admin,
                    deviceToken: userData.deviceToken,
                    isiOS: userData.isiOS,
                    premium: userData.premium
                }),
            })

            if (response.ok) {

                console.log("createUser is okey, ", response);

            }

        } catch (error) {
            console.log("Error in createUser: ", error);
        }

    }

    return { createUser }
}
