import React from 'react';
import "./Course.css";

const HowInflationWorks = () => {
    const courseText = [
        "How Inflation Works",
        "Creator: Swollet.",
        "Inflation is when you pay fifteen dollars for the ten-dollar haircut you used to get for five dollars when you had hair.",
        "An illustrated guide for the rest of us",
        "What Is Inflation?",
        '― Sam Ewing',
        'Inflation is an economic term that describes a general increase in prices and a fall in the purchasing power of money.',
        'A little inflation is normal and even good for a healthy economy. Inflation becomes a problem when it grows too quickly. Money losing value at a rapid rate can lead an entire economy to spiral out of control. All governments and central banks try and control inflation with regulation and monetary policy. Inflation is expressed as a percentage.',
        "Rise in prices",
        "In 1950 you could buy a cup of coffee for \$0.22 while today an average cup of brew costs \$2.70.",
        "Falling purchasing power of money",
        "A basket of goods that cost \$100 in 1950 would cost \$1,108 today.",
        "Inflation  = Rise in costs of goods and services",
        "Inflation rate  = % increase or decrease in prices over a period of time",
        "What Causes Inflation?",
        "Inflation is always and everywhere a monetary phenomenon, resulting from and accompanied by a rise in the quantity of money relative to output.",
        "― Milton Friedman",
        "In simpler terms, inflation occurs when there's an increase in production costs OR when demand for products and services increases faster than supply. Inflation can come about in many different ways. All these causes are most commonly classified into three main types of inflation.",
        "3 Main Types of Inflation (By Causes)",
        "Cost-Push Inflation",
        "Prices increase when the cost of production increases. If it costs more to make a product or provide a service the companies will pass that cost on to consumers by increasing the price of those products and services. Here are some things that can cause cost-push inflation:",
        "Increase in prices of raw materials",
        "If the price of oil rises, all industries that rely on oil for production will increase their prices to offset that rise in cost.",
        "Natural disasters",
        "Natural disasters can disrupt supply chains or destroy a production facility. This makes it more expensive for businesses to operate and they will raise their prices to make up for the loss.",
        "Scarcity of a resource",
        "If a resource needed for production is scarce that makes it more difficult and expensive for businesses that rely on it to operate. Overfishing leads to fish being scarce and that's why the price of fish is rising.",
        "Low unemployment rate",
        "When the unemployment is low companies increase wages to attract more workers. Production costs rise due to increasing wages and the company raises the price of their product.",
        "Increase in mandatory wage",
        "When businesses are required by law to increase the wages of their workers they will raise their prices to offset the added expense.",
        "Government regulation",
        "Increased regulation often means added expenses for businesses that have to invest in new equipment or additional workforce in order to comply with new rules.",
        "Rise in business taxes",
        "When the government raises business taxes the cost of production, and consequently the prices, will increase across all industries.",
        "Declining productivity",
        "A workforce with declining productivity will work less for the same wages. This means that companies are paying the same amount to produce fewer products.",
        "Demand-Pull Inflation",
        'Prices increase when demand increases faster than production. If everybody wants to buy something that is in limited supply they will be willing to pay more money for it and the companies will charge more for the same product or service. Here are some things that can cause demand-pull inflation:',
        "Growing economy",
        "When things are going well, people have jobs and they are confident that the good times will last they tend to spend more money and create demand.",
        "When things are going well, people have jobs and they are confident that the good times will last they tend to spend more money and create demand.",
        "When things are going well, people have jobs and they are confident that the good times will last they tend to spend more money and create demand.",
        "Inflation expectations",
        "Inflation is nothing new and people actually count on it. when you expect prices to rise in the future, you'll buy more things now.",
        "Increase in the money supply",
        "When governments print too much money this creates an oversupply of money in circulation. The same goes for when central banks lower the interest rate and banks start lending out more money to people.",
        "Low interest rates",
        "When the interest rates are low people tend to borrow more money, which means that they have more money to spend.",
        "Government spending",
        "When the government invests in infrastructure or hires people money gets injected into the economy causing prices to rise.",
        "Tax cuts",
        "When a country lowers its taxes people end up having more money to spend.",
        "Innovation",
        "People love new technology and will pay more for it. If a product offers something unique and innovative the demand for it will be very strong.",
        "Marketing",
        "A strong demand for a product or a service can also be created by marketing alone. Think how the demand for Apple products is always high even though their prices are way higher than their competitors.",
        "Unbalanced recovery from a downturn",
        "If the economy is stalled by an external event (like a pandemic) demand may recover more quickly than production capacity, especially if governments make stimulus payments. This may cause demand to temporarily exceed supply.",
        "Built-In Inflation (Wage-Price Spiral)",
        'Workers want higher wages to keep up with the cost of living. When prices rise due to cost-push or demand-pull inflation people expect higher wages so they can keep their lifestyle and standard of living. Higher wages make companies increase the price of their goods and services. This raises the cost of living and makes workers demand higher wages.',
        "Increase in the Money Supply and Inflation",
        "Most people will correlate governments printing money with inflation. An increase in the overall money supply can sometimes lead to both cost-push and demand-pull inflation. Money supply creates inflation only when the money is printed faster than the economy grows. Keep in mind that the money supply is not just hard cash, but also credit, loans, and mortgages.",
        "Monetary Policy",
        "When central banks lower interest rates it's cheaper for banks to lend money. Banks will then lend more money to businesses and individuals who will spend it. More money being spent increases demand, which increases prices.",
        "Fiscal Policy",
        "If the government cuts taxes, makes stimulus payments, or increases benefits, people have more money to spend. If business taxes are cut businesses can increase wages or hire more people. People have more money, they spend more, demand increases, which increases prices.",
        "Exchange Rates",
        "When there's more money in circulation the currency loses value in relation to foreign currencies. This makes imported products more expensive because now your currency has less purchasing power. The governments can also choose to lower the exchange rates to make local products more competitive. This will also lead to imports being more expensive.",
        "It might be worth pointing out that increasing the money supply only increases demand if the extra money ends up in the hands of consumers.",
        "For example, in the wake of the 2008 recession interest rates were very low but loan criteria were very tight. This meant that the cheap money flowed mainly to the rich. As a consequence, we saw rapid inflation in asset prices (rich people buy stocks and real estate) and much less inflation in consumer goods.",
        "Who Benefits and Who Gets Hurt by Inflation?",
        "Inflation does not impact everyone the same. Some people get hurt by the fall in the value of currency while others can benefit from it.",
        "Winners",
        "Debtors",
        " - especially if their debts carry fixed rates. They pay their loans back in less valuable currency.",
        "Owners of land and physical assets",
        " - These assets tend to hold their value through inflationary periods.",
        "Break-even",
        "Workers whose salaries are indexed to inflation",
        "will not be adversely affected by inflation.",
        "Losers",
        "Savers",
        " - If the inflation rate is higher than the interest rate, savings are decreasing in value.",
        "Retirees on fixed incomes",
        " - If you have a fixed pension or interest income from investments your income will not grow with inflation.",
        "Workers on fixed-wage contracts",
        " - If your wage doesn't rise with inflation, it is effectively getting smaller.",
        "Borrowers with variable-rate loans",
        " - Governments often raise interest rates to try to make their currency more desirable. That can push interest rates on variable-rate loans up.",
        "Lenders of fixed rate loans",
        " - If you lent money at a fixed rate you are getting paid back in less valuable currency.",
        "Workers on fixed-wage contracts",
        " - If your wage doesn't rise with inflation, it is effectively getting smaller.",
        "Borrowers with variable-rate loans",
        " - Governments often raise interest rates to try to make their currency more desirable. That can push interest rates on variable-rate loans up.",
        "Lenders of fixed rate loans",
        " - If you lent money at a fixed rate you are getting paid back in less valuable currency.",
        "How Is Inflation Measured",
        "To measure inflation we need to use a device called an inflation index. There are multiple ways to measure inflation and that's why several inflation indexes exist. It's impossible to keep a track of price changes of every single product or service in the economy. That's why inflation indexes follow price changes of only a small number of goods and services.",
        "Consumer Price Index (CPI)",
        "The most well-known inflation index is the Consumer Price Index (CPI). CPI examines the average price of a hypothetical basket of goods and services in order to see if there are any changes in the overall cost of living. Different items in the basket carry different weights. These weights reflect the varying importance of items in the consumer’s shopping basket (having a roof over your head carries more weight than having a gym membership). The increase or decrease in prices of all the items when expressed as a percentage represents the inflation rate.",
        "What's in the Basket of Goods?",
        "Each country can use its own methodology, define its own basket of goods and assign different weights to each item in the basket. This makes sense because people living in different places will have different everyday needs and priorities. The items in the basket change almost every year to better reflect the current needs and wants of consumers. Here are the main categories of the basket of goods the U.S. (urban consumers):",
        "Food and beverages",
        "Food you consume at home and outside of the home, non-alcoholic and alcoholic beverages.",
        "Costs associated with owning or renting a home, utilities, household furnishings, and maintenance.",
        "Apparel",
        "Clothes, shoes, jewelry, and watches.",
        "Transportation",
        "New and used vehicles, fuel, vehicle parts and maintenance, fees like parking and registration, public transport.",
        "Medical care",
        "Health insurance, drugs, physician services, dental services, eye care, hospital services, nursing homes...",
        "Recreation and entertainment",
        "Video and audio, pets, sporting goods, hobby supplies, toys, club memberships, lesson fees, books, and magazines...",
        "Education and communication",
        "Tuition, educational books, postage and delivery services, phones, computers, internet...",
        "Other goods and services",
        "Tuition, educational books, postage and delivery services, phones, computers, internet...",
        "What's in the Baskets Around the World?",
        "Each basket of goods is commonly sorted by the main categories above and in most countries the items in those categories are the same. But still, there are some curiosities to be found around the world:",
        "Speed of Inflation",
        "There are four main types of inflation, categorized by their speed. They are creeping, walking, galloping, and hyperinflation.",
        "Creeping Inflation (The Good One)",
        "This is when the inflation rate rises by up to 3% each year. It is good for economic growth since it drives consumers to buy now and avoid higher prices in the future. That boosts demand while production and wages have enough time to keep up.",
        "Walking Inflation",
        "This is when inflation is still in single digits, between 3% and 10%, and lasts for a longer period of time.",
        "Inflation usually becomes a cause for concern when it goes over 4%. This type of inflation is damaging to the economy because consumers start buying way more than they need in order to avoid higher prices in the future. When that happens, the production and wages cannot keep up. Producers will increase their prices due to higher demand and because the wages are not growing people will not be able to afford some goods and services.",
        "Galloping Inflation",
        "This is when inflation rises above 10%. At this rate, inflation becomes a serious problem and is hard to control. Money loses value at such a rapid rate that the wages have no chance of keeping up with the prices. It has huge adverse effects on the population of a country, especially the poor and the middle class. In order to control galloping inflation countries need to adopt strong fiscal and monetary measures.",
        "When inflation reaches 50% a month (over 1000% a year) it is called hyperinflation. This is the most extreme form of inflation and there have only been 58 episodes of hyperinflation in recorded history. Prices rise several times in a single day - something that costs \$10 in the morning could cost \$100 when you finish work in the afternoon. Money is becoming worthless at such a rate that the government has to print money in larger and larger denominations. You can end up paying \$150 billion (Elon Musk's current net worth) for a loaf of bread... Prices rising at such a devastating rate leads people to start hoarding goods, leading to shortages of everything. In most cases, people will face severe food shortages. Savings, investments, and pensions become worthless because of the fall in the purchasing power of money. People stop using banks which leads to banks and other lenders going out of business. The collapse of the whole monetary system is inevitable.",
    ];

    return (
        <div>
            <h1>How Inflation Works</h1>
             {courseText.map((text, index) => (
                <p key={index} className="crypto-content">{text}</p>
            ))}
        </div>
    )
}

export default HowInflationWorks