import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

interface Props{
    name: string;
    url: string
}

const ButtonCourse: React.FC<Props> = ({ name, url }) => {
  const navigate = useNavigate();

  function handleClick() {
    console.log("url: " + url);
    navigate(`/${url}`);
  }

  return (
    <button className="button btn-primary" onClick={handleClick}>
      {name}
    </button>
  );
}

export default ButtonCourse;
