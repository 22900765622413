import React from 'react';
import { useUserContext } from '../hooks/useUserContext';
import "./Login.css";
import "./Profile.css";

const InvestmentCalculator = () => {
  return (
    <div>
        <div className="wrapper">
            <div className="content">
                Investment Calculator
            </div>
        </div>
    </div>
  )
}

export default InvestmentCalculator
