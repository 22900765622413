import React from 'react';
import telegram from '../images/telegram.svg';
import discord from '../images/discord.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import ln from '../images/ln.svg';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
            <div className="footer-wrapper">
                <p className="large">©Swollet. All rights reserved</p>
                <div className="social">
                    <a href="https://t.me/swollet">
                        <img src={telegram} alt=""/>
                    </a>
                    <a href="https://discord.gg/YwJFgSZ6zE">
                        <img src={discord} alt=""/>
                    </a>
                    <a href="https://www.facebook.com/swolletapp">
                        <img src={facebook} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/teamswollet/">
                        <img src={instagram} alt=""/>
                    </a>
                    <a href="https://www.linkedin.com/company/swollet">
                        <img src={ln} alt=""/>
                    </a>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer
