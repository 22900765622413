import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import { useCourses } from "../hooks/useCourses";
import { useLogOut } from "../hooks/useLogOut";
import "./Courses.css";
import web3 from '../images/web3.png';
import finance from '../images/finance.png';
import tech from '../images/tech.png';
import crypto from '../images/crypto.png';
import logo from '../images/logo.svg';
import "./Swollet.css";
import telegram from '../images/telegram.svg';
import discord from '../images/discord.svg';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.svg';
import ln from '../images/ln.svg';
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
} from '@chakra-ui/react';

const Courses: React.FC = () => {
    const navigate = useNavigate();
    //const { user } = useAuthContext();
    const { state: { user } } = useAuthContext();
    const { state: { userData } } = useUserContext();
    const { allCourses, courses, isLoading } = useCourses();
    const { logout } = useLogOut();
    const [isMobile, setIsMobile] = useState(false);
    //const [allCourses, setAllCourses] = useState<any>();

    const handleClickCrypto = () => {
        navigate("/crypto");
    }

    const handleClickFinance = () => {
        navigate("/finance");
    }

    const handleClickTech = () => {
        navigate("/tech");
    }

    const handleClickWeb3 = () => {
        navigate("/web3");
    }

    const handleSignOut = async () => {
        try {
            await logout();
            navigate("/login")
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {

        /* ******************************** */
        courses(user.stsTokenManager.accessToken);
        /* ******************************** */

        console.log(" Courses --> ", allCourses);

        console.log("User in Courses --> ", user);

        const updateIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
      
          // Initial check
        updateIsMobile();
      
          // Event listener for window resize
        window.addEventListener('resize', updateIsMobile);
      
          // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateIsMobile);
        };

    }, [user])

    return (
        <div>
            <div className="wrapper register-back">
                <div className="content">

                    <header className="header">
                            <div className="header-wrapper ">
                                <a href="/" className="logo">
                                    <img src={logo}  alt=""/>
                                </a>

                                {isMobile ? (
                                // Render the dropdown menu for mobile screens
                                <div id="hamburger-menu">
                                   <Menu>
                                    <MenuButton as={Button} className="button btn-primary">
                                    Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/home")}}>Home</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/courses")}}>Courses</MenuItem>
                                        <MenuItem className="button btn-primary" onClick={() => {navigate("/profile")}}>Profile</MenuItem>
                                        <MenuItem className="button btn-primary">Logout</MenuItem>
                                    </MenuList>
                                    </Menu>
                                </div>
                            ) : (
                                // Render the buttons for larger screens
                                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                    <button className="button btn-primary">Messages</button>
                                    <button onClick={handleSignOut} className="button btn-primary">
                                        Logout
                                    </button>
                                </div>
                            )}
                            
                            </div>
                    </header>

                    <section className="hero">
                        <div className="hide">
                            <Sidebar/>
                        </div>
                        <div className="centered-container" >
                            <button onClick={handleClickCrypto} className="box-course">
                                <h1>Crypto</h1>
                                <h3>12 Courses</h3>
                                <img src={crypto}/>
                            </button>
                            <button onClick={handleClickFinance} className="box-course">
                                <h1>Finance</h1>
                                <h3>49 Courses</h3>
                                <img src={finance}/>
                            </button>
                            <button onClick={handleClickTech} className="box-course">
                                <h1>Tech</h1>
                                <h3>7 Courses</h3>
                                <img src={tech}/>
                            </button>
                            <button onClick={handleClickWeb3} className="box-course">
                                <h1>Web3</h1>
                                <h3>9 Courses</h3>
                                <img src={web3}/>
                            </button>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default Courses;
