import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Login.css";
import Button1 from "../components/Button1";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import { useRegister } from "../hooks/useRegister";
import { useLogin } from "../hooks/useLogin";
import { useCreateUser } from "../hooks/useCreateUser";

interface User {
    email: string;
    password: string;
}

// rendered in the /login route
const LoginEmail: React.FC = () => {
  const navigate = useNavigate();

  const { state: { user } } = useAuthContext();
  const { state: { userData } } = useUserContext();

  const { googleSignIn, appleSignIn, emailSignIn, setUpRecaptha } = useLogin();

  const { createUser } = useCreateUser();

  const [loginUser, setLoginUser] = useState<User>({
    email: "",
    password: "",
  });

  function handleChangeLogin(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
  
    setLoginUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value
      };
    });

    console.log("login user" + loginUser.email + " and " + loginUser.password);
  }

  const handleEmailSignIn = async () => {
    try {
      await emailSignIn(loginUser.email, loginUser.password);

      /*if(user != null) {

        console.log("$$$$$$$$$$$$$$$$$$$$$$")

        await createUser(user.stsTokenManager.accessToken, userData);

        navigate("/home");
      }*/

      console.log("user: ", user );

    } catch (error) {
      //const { showAlert } = useAlertContext(); // Get the showAlert function from the AlertContext
      //showAlert("An error occurred during sign-in. Please try again."); // Show the error alert message
      console.log(error);
    }
  };

  function handleClickRegister(): void {
    navigate("/register");
  }
  
  

  return (
    <div>
      <div className="wrapper">
        <div className="content">
            <div className="login-title">Login via Email </div>
            <div className="login-content">
             
              
                  <div className="form__group field input1">
                      <input 
                        onChange={handleChangeLogin}
                        name="email" 
                        id="email" 
                        placeholder="Email" 
                        value={loginUser.email} 
                        type="email"
                        className="form__field"  
                        required 
                      />
                      <label htmlFor="email" className="form__label">Email</label>
                    </div>

                    <div className="form__group field input1">
                      <input 
                        onChange={handleChangeLogin}
                        name="password" 
                        id="password"
                        placeholder="Password" 
                        value={loginUser.password} 
                        type="password" 
                        className="form__field"  
                        required 
                      />
                      <label htmlFor="password" className="form__label">Password</label>
                    </div>

                    <div className="requirements">
                      <p>8 characters min</p>
                      <p>At least 1 letter</p>
                      <p>At least 1 number</p>
                    </div>

                  <button name="Submit" className="btn btn-primary a1" onClick={handleEmailSignIn}>Continue</button>
                  
              
            </div>
            <div className="have-account">
                <div className="a1">Or if you have not an account:</div>
                <div className="button-account a1" onClick={handleClickRegister}><Button1 name="Register" url="register"/></div>
            </div>
        </div>
      </div>
    </div>

  );
}

export default LoginEmail;