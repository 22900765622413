import React from 'react';
import { useState } from "react";

export const useGetUser = () => {

    const getUser = async (idToken: string) => {

        console.log("We are into useGetUser: ", idToken)

        try{

            const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/getudata?idToken=${idToken}`);
            // is used in the useLogin.tsx

            if (response.ok) {
            
                console.log("getUser is okey, ", response);
                const json = await response.json();
                console.log("Response from getUser: ", json);

                return json; // return the user 
            }

        } catch (error) {
            console.log("Error in createUser");
        }

    }

    return { getUser }
}