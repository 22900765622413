import React from 'react';
import { useState, useEffect } from "react";

export const useUpdate = () => {

    const updateUser = async (fieldToUpdate: string, newValue: any, idToken: string) => {

        console.log("We are into useGetUser: ", idToken)

        try{

            const response = await fetch(`https://us-central1-swollet-25943.cloudfunctions.net/app/userupdate?idToken=${idToken}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // SOS when we send the body like here
                },
                body: JSON.stringify({
                    fieldToUpdate : fieldToUpdate,
                    newValue: newValue
                }),
            })

            if (response.ok) {
            
                console.log("useUpdate is okey, ", response);
                //const json = await response.json();
                //console.log("Response from updateUser: ", json);

                //return json; // return the user 
            }

        } catch (error) {
            console.log("Error in createUser");
        }

    }

    return { updateUser }
}
