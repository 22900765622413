import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../pages/Firebase';
import { useUserContext } from './useUserContext';


// To register the user
export const useLogOut = () => {
    const { dispatch } = useAuthContext();
    const { dispatch: userDispatch } = useUserContext();

    const logout = async () => {
        // get the firebase instance and log out of it
        signOut(auth);

        // remove the user to local storage
        localStorage.removeItem('user');
        localStorage.removeItem('userData');

        // update the auth context
        dispatch({type: 'LOGOUT'})
        userDispatch({ type: 'LOGOUT' });

    }

    return { logout }
}