import React from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { Select } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
import "../css/Courses.css";
import ButtonCourse from '../components/ButtonCourse';

const TechCourseContent: React.FC = () => {
    //const { user } = useAuthContext();
    const { state: { user } } = useAuthContext();

    return (
        <div>
            <div className="wrapper">
            <Accordion allowToggle className="courses">
                <AccordionItem className="courses-item">
                    <h2>
                    <AccordionButton className="courses-item-button">
                        <Box as="span" flex='1' textAlign='left'>
                            Artificial Intelligence (AI)
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="courses-item-panel">

                        <ButtonCourse name="01 Introduction to Artificial Intelligence (AI)" url="tech/IntroToAI"/>

                        <ButtonCourse name="02 How does AI work" url="tech/howdoesAIwork"/>

                        <ButtonCourse name="03 Applications of AI" url="tech/applicationsofai"/>

                        <ButtonCourse name="04 The potential benefits of AI" url="tech/potentialbenefitsofAI"/>

                        <ButtonCourse name="05 The potential risksand ethical considerations of AI" url="tech/potentialrisksandethicalconsiderationsofAI"/>

                        <ButtonCourse name="06 The future of AI" url="tech/thefutureofAI"/>

                        <ButtonCourse name="07 Getting started with AI" url="tech/gettingstartedwithAI"/>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            </div>
        </div>
    )
}

export default TechCourseContent;