import React, { useEffect, useState} from 'react';
import {useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Button1 from '../components/Button1';
import ln from '../images/ln.svg';
import logo from '../images/logo.svg';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react'

const CompetitionRules = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [textSlideIndex, setTextSlideIndex] = useState(1);
  const navigate = useNavigate();
  let interval: NodeJS.Timeout;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {

      const updateIsMobile = () => {
          setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };
  
      // Initial check
      updateIsMobile();
  
      // Event listener for window resize
      window.addEventListener('resize', updateIsMobile);

      return () => {
          clearInterval(interval);
          window.removeEventListener('resize', updateIsMobile);
      };

  }, []);

  return (
    <div>
      <div className="wrapper">
        <div className="content">
            <header className="header">
                <div className="header-wrapper">
                    <a href="/" className="logo">
                        <img src={logo}  alt=""/>
                    </a>
                    {isMobile ? (
                            // Render the dropdown menu for mobile screens
                            <div id="hamburger-menu">
                                <Menu>
                                <MenuButton as={Button} className="button btn-primary">
                                Actions
                                </MenuButton>
                                <MenuList>
                                    <MenuItem className="button btn-primary" onClick={() => {navigate("/business")}}>Business</MenuItem>
                                    {/*<MenuItem className="button btn-primary" onClick={() => {navigate("/login")}}>Login</MenuItem>*/}
                                </MenuList>
                                </Menu>
                            </div>
                        ) : (
                            // Render the buttons for larger screens
                            <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                <Button1 name="Business" url="business"/>
                                {/*<Button1 name="Login" url="login"/>*/}
                            </div>
                        )}
                </div>
            </header>
            <header className="header sticky">
                <div className="header-wrapper">
                    <a href="/" className="logo">
                        <img src={logo} alt=""/>
                    </a>
                    {/*<a href="/business"><button className="button btn-primary">For Business</button></a>*/}
                    <Button1 name="Business" url="business"/>
                    <Button1 name="Login" url="login"/>
                </div>
            </header>
        <section className="hero">
          <div className="container">
            <div className="hero-wrapper">
              <h1 className="h1 green-gradient">Competition Rules<br /></h1>
            </div>

            <div className="about-wrapper">
              <div className="aos-init aos-animate">
                <h2 className="green-gradient" style={{marginTop: "50px"}}>Rules</h2>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Starting Balance:</h3>

                <p className="large">
                  Participants will start the competition with a virtual balance of $100,000 USD.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Trading Assets:</h3>

                <p className="large">
                  Participants can trade a variety of assets available on the Swollet app, including stocks, ETFs, and cryptocurrencies.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Account Verification:</h3>
                <p className="large">          
                All participants must complete the account verification process on Swollet, including providing accurate contact information. Winners will be contacted for prize distribution and must provide either an IBAN or a PayPal account for cash rewards.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Trading Hours:</h3>
                <p className="large">          
                The virtual trading game operates during standard US market hours. Stock and cryptocurrency data are real-time but may have a 15-30 minute delay to simulate real-world conditions.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Order Types:</h3>
                <p className="large">
                Participants can only place market orders during the competition. Fractional shares are available too.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Fair Play:</h3>
                <p className="large">
                Participants are expected to engage in ethical and fair trading practices. Any form of cheating, collusion, or fraudulent activity will result in disqualification.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Winners and Rankings:</h3>
                <p className="large">
                Rankings will be determined based on the virtual portfolio's performance throughout the competition period. The participant with the highest portfolio value at the end of the competition will be awarded the 1st place prize, followed by 2nd and 3rd place.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Prizes:</h3>
                <p className="large">
                Prizes will be awarded as mentioned in the terms and conditions. Cash rewards will be distributed to the winners via the provided IBAN or PayPal account.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Liability:</h3>
                <p className="large">
                  Participants acknowledge that this is a simulated trading environment and not real financial trading.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Governing Rules:</h3>
                <p className="large">
                  These rules are subject to the terms and conditions outlined in the competition's terms and conditions.
                </p>
                <p className="large">
                  By participating in the Swollet Virtual Trading Game Competition, participants acknowledge and agree to abide by these rules.
                </p>

                <h2 className="green-gradient" style={{marginTop: "50px"}}>Terms & Conditions</h2>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Eligibility:</h3>
                <p className="large">
                  Participants must be at least 18 years old to enter.
                  Participants must download the Swollet mobile app and create a valid account to be eligible.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Competition Period:</h3>
                <p className="large">
                  The competition will run from February 1, 2024, to February 29, 2024.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Prizes:</h3>
                <p className="large">

                  Prizes will be awarded as follows:
                  <li>🥇1st Place: €100</li>
                  <li>🥈2nd Place: €75</li>
                  <li>🥉3rd Place: €25</li>
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Winners:</h3>
                <p className="large">
                  Winners will be determined based on their performance in the virtual trading game during the competition period.

                  Rankings and winners will be announced on March 1, 2024, on the Swollet mobile app and official Swollet social media channels.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Disqualification:</h3>
                <p className="large">
                  Swollet reserves the right to disqualify participants found engaging in fraudulent or unethical trading practices.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Privacy:</h3>
                <p className="large">

                Participant information will be handled in accordance with Swollet's privacy policy.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>General Conditions:</h3>
                <p className="large">

                By participating, participants agree to abide by these terms and conditions.
                Swollet reserves the right to modify or cancel the competition at any time without prior notice.
                Participants are responsible for any taxes or fees associated with their prize winnings.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Liability:</h3>
                <p className="large">
                  Swollet is not responsible for any financial losses incurred during the virtual trading game.
                  Swollet is not liable for any technical issues or interruptions during the competition.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Governing Law:</h3>
                <p className="large">
                  These terms and conditions are governed by and construed in accordance with the laws of the Republic of Ireland.
                </p>

                <h3 className="green-gradient" style={{marginTop: "50px"}}>Contact:</h3>
                <p className="large">
                  For any questions or inquiries regarding the competition, please contact Swollet's customer support at info@swollet.com

                  By participating in the Swollet Virtual Trading Game Competition, participants acknowledge and agree to these terms and conditions.
                </p>
            </div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  )
}

export default CompetitionRules