import React from "react";
import "./AlertDialog.css"; // Import the CSS file for styling

interface Props{
  message: string;
}

const AlertDialog: React.FC<Props> = ({ message }) => {
  return (
    <div className="alert-dialog-container">
      <div className="alert-dialog">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default AlertDialog;