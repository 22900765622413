import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Login.css";
import Button1 from "../components/Button1";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import { useRegister } from "../hooks/useRegister";

interface User {
    email: string;
    password: string;
}

// rendered in the /register route
const RegisterEmail: React.FC = () => {
  const navigate = useNavigate();
  //const { user } = useAuthContext();
  const { state: { user } } = useAuthContext();

  const { googleRegister, appleRegister, emailRegister } = useRegister();

  const [registerUser, setRegisterUser] = useState<User>({
    email: "",
    password: "",
  });

  function handleChangeRegister(event: React.ChangeEvent<HTMLInputElement>) {
      const { name, value } = event.target;
    
      setRegisterUser((prevValue) => {
        return {
          ...prevValue,
          [name]: value
        };
      });
  }

  function handleClickLogin(): void {
    navigate("/login");
  }

  const handleEmailRegister = async () => {
    try {
      await emailRegister(registerUser.email, registerUser.password);
      if(user != null) {
        navigate("/home");
      }
      console.log("user: ", user); 
    } catch (error) {
      console.log("error: ", error);
    }
  }

    /*async function isAllValid(event: React.FormEvent<HTMLFormElement>){
      //lastname firstname email->@ password->one number and 8 letter minimum

      if(!regUser.email.includes("@")){
        alert("The email must conclude '@'");
        event.preventDefault();
      }

      if(regUser.password.length < 8){
        alert("The length of password must be > 8");
        event.preventDefault();
      }

      if(!/\d/.test(regUser.password)){
        alert("The password must have a number");
        event.preventDefault();
      } 
    }*/

 /* async function handleSubmit(e) {
    e.preventDefault()

    // Here we use the useRegister hook from /hooks/useRegister.js file
    await register(regUser.firstName, regUser.lastName, regUser.email, regUser.username, regUser.password); 
  }*/
  
  

  return (
    <div>
      <div className="wrapper">
          <div className="content">
            <div className="register-title">Register via email</div>
              <div className="register-content">
                
                    
                    <div className="form__group field input1">
                      <input 
                        onChange={handleChangeRegister}
                        name="email" 
                        id="email" 
                        placeholder="Email" 
                        value={registerUser.email} 
                        type="email"
                        className="form__field"  
                        required 
                      />
                      <label htmlFor="email" className="form__label">Email</label>
                    </div>

                    <div className="form__group field input1">
                      <input 
                        onChange={handleChangeRegister}
                        name="password" 
                        id="password"
                        placeholder="Password" 
                        value={registerUser.password} 
                        type="password" 
                        className="form__field"  
                        required 
                      />
                      <label htmlFor="password" className="form__label">Password</label>
                    </div>

                    <div className="requirements">
                      <p>8 characters min</p>
                      <p>At least 1 letter</p>
                      <p>At least 1 number</p>
                    </div>

                    <button name="Submit"  className="btn btn-primary a1" onClick={handleEmailRegister}>Continue</button>
                    
                
              </div>
              <div className="have-account">
                  <div className="a1">Or if you have acount:</div>
                  <div className="button-account a1" onClick={handleClickLogin}><Button1 name="Login" url="login"/></div>
              </div>
            </div>
        </div>
    </div>

  );
}

export default RegisterEmail;