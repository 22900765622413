import React from 'react';
import { Timestamp } from 'firebase/firestore';
import { auth } from '../pages/Firebase';

/*export const useDate = () => {
    const date = () => {
        const currentDate = new Date();
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[currentDate.getMonth()];
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();

        // Calculate the UTC offset in hours and minutes
        const timeZoneOffset = -currentDate.getTimezoneOffset();
        const timeZoneOffsetHours = Math.floor(timeZoneOffset / 60);
        const timeZoneOffsetMinutes = timeZoneOffset % 60;

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;

        const formattedDateTime = `${month} ${day}, ${year} at ${formattedHours}:${minutes}:${seconds} ${ampm} UTC`;

        // Add the UTC offset to the formatted date
        const formattedDateTimeWithOffset = `${formattedDateTime}+3`;

          // Get the timestamp in milliseconds
        const timestamp = currentDate.getTime();

        return {
            formattedDateTimeWithOffset,
            timestamp
        };
    };

    return { date };
};*/

export const useDate = () => {

    const date = () => {
        // Create a new Date object with the current date and time
        /*const currentDateTime = new Date();

        //console.log("getUTC: ", currentDateTime.getUTCDate());

        //const myTimestamp = firebase.firestore.Timestamp.fromDate(new Date());

        //console.log("myTimestamp: ", myTimestamp);

        // Set the UTC offset to UTC+3
        const offsetInHours = 3;
        currentDateTime.setHours(currentDateTime.getHours() + offsetInHours);

        // Format the date as desired with the 'timeZone' option set to 'UTC+3'
        const formattedDateTimeWithOffset = currentDateTime.toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: 'UTC',
          timeZoneName: 'short',
        });*/

        // Convert the formatted date string to a JavaScript Date object
        //const parsedDate = new Date(formattedDateTimeWithOffset);

        //console.log("parseDate: ", parsedDate);

        // Get the timestamp (milliseconds since Unix epoch)
        //const timestamp = parsedDate.getTime();

        // Get the current JavaScript Date
        const now = new Date();

        // Create a Timestamp from the current date
        const timestamp = Timestamp.fromDate(now);
 
        return {
           timestamp
        };

        /*return {
          formattedDateTimeWithOffset
        };*/
    };

    const formatTimestamp = (timestamp: Timestamp) => {
        // Convert Firebase Timestamp to JavaScript Date
        const date = timestamp.toDate();

        // Get UTC offset in minutes
        const utcOffsetMinutes = date.getTimezoneOffset();

        // Calculate the UTC offset in hours and minutes
        const utcOffsetHours = Math.abs(Math.floor(utcOffsetMinutes / 60));
        const utcOffsetMinutesRemaining = Math.abs(utcOffsetMinutes % 60);

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        }).format(date);

        // Construct the final string
        const formattedTimestamp = `${formattedDate} UTC${utcOffsetHours >= 0 ? '+' : '-'}${utcOffsetHours}:${utcOffsetMinutesRemaining.toString().padStart(2, '0')}`;

        return formattedTimestamp;
    };


    const toStringDate = (date: Date, options: { withTime?: boolean; withMonthName?: boolean } = {}): string => {
        const { withTime = false, withMonthName = false } = options;

        const fixedDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
        const fixedMonth = date.getMonth() < 9 ? '.0' + (date.getMonth() + 1) : '.' + (date.getMonth() + 1);
        const fixedYear = '.' + date.getFullYear();

        const now = new Date();
        const difference = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

        let stringDate = `${fixedDay}${fixedMonth}${fixedYear}`;

        if (difference >= -1 && difference < 7) {
            const todayOrYesterday = difference === 0 ? 'Today' : (difference === -1 ? 'Yesterday' : getDayName(date));
            stringDate = todayOrYesterday;
        } else if (withMonthName) {
            const monthName = getMonthName(date);
            stringDate = `${fixedDay} ${monthName} ${date.getFullYear()}`;
        }

        if (withTime) {
            const fixedHour = date.getHours() < 10 ? ' 0' + date.getHours() : ' ' + date.getHours();
            const fixedMinute = date.getMinutes() < 10 ? ':0' + date.getMinutes() : ':' + date.getMinutes();
            stringDate += `${fixedHour}${fixedMinute}`;
        }

        return stringDate;
    };

    const isClosedDay = (date: Date): boolean => {
        const b = date.getDay() === 6 || date.getDay() === 0;
        const c = date.getHours() < 9 || (date.getHours() === 9 && date.getMinutes() < 30);
        const d = date.getHours() > 16;

        return b || c || d;
    };

    const toEst = (date: Date): Date => {
        const utcDate = new Date(date.getTime() - 4 * 60 * 60 * 1000); // Subtract 4 hours to convert to EST
        return utcDate;
    };

    const toOpenDate = (date: Date, start = false): Date => {
        let sub = 0;

        if (date.getDay() === 6) {
            sub = 1;
        } else if (date.getDay() === 0) {
            sub = 2;
        } else if (date.getDay() === 1 && date.getHours() < 11) {
            sub = 3;
        }

        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - sub, start ? 0 : 23, start ? 0 : 59);
    };

    const toOpenDate2 = (date: Date): Date => {
        let addition = 0;

        switch (date.getDay()) {
            case 6:
                addition = 2;
                break;
            case 0:
                addition = 1;
                break;
            default:
                break;
        }

        const newDate = new Date(date.getTime() + addition * 24 * 60 * 60 * 1000); // Add days
        newDate.setUTCHours(9, 30, 0, 0); // Set time to 09:30 UTC

        return newDate;
    };

    const toClosedDate = (date: Date): Date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0, 0, 0); // Set time to 16:00 UTC
    };

    const getTodayOrYesterday = (date: Date): string | null => {
        const now = new Date();
        const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

        if (diffInDays === 0) {
            return 'Today';
        } else if (diffInDays === 1) {
            return 'Yesterday';
        } else {
            return null;
        }
    };

    const getDayName = (date: Date): string => {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        return days[date.getDay()];
    };

    const getMonthName = (date: Date): string => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'
        ];

        return months[date.getMonth()];
    };

    return {
        date,
        formatTimestamp,
        toStringDate,
        isClosedDay,
        toEst,
        toOpenDate,
        toOpenDate2,
        toClosedDate,
        getTodayOrYesterday,
        getDayName,
        getMonthName
    };
};
