import React from 'react';
import { useLogOut } from "../hooks/useLogOut";
import "./Login.css";
import "./Profile.css";
import "./Courses.css";
import arrow from '../images/arrow.svg';
import { useNavigate } from "react-router-dom";
import logo from '../images/logo.svg';
import Sidebar from "../components/Sidebar";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button
  } from '@chakra-ui/react';

const Community = () => {
    const navigate = useNavigate();
    const { logout } = useLogOut();

    const handleSignOut = async () => {
        try {
            await logout();
            navigate("/login")
        } catch (error) {
            console.log(error);
        }
    };


    return (
    <div>
        <div className="wrapper register-back">
            <div className="content">

                <header className="header">
                        <div className="header-wrapper ">
                            <a href="/" className="logo">
                                <img src={logo}  alt=""/>
                            </a>

                            <div style={{ display: 'flex', paddingLeft: '5px' }}>
                                <button className="button btn-primary">Messages</button>
                                <button onClick={handleSignOut} className="button btn-primary">
                                    Logout
                                </button>
                            </div>
                        </div>
                </header>

                <section className="hero">
                    <Sidebar/>
                    <h1 className="centered-container">Community</h1>
                    <h3 className="centered-container" style={{marginTop: "150px"}}>Coming soon ... </h3>
                </section>
                </div>
            </div>
        </div>
    )
}

export default Community
