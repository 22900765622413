import React, { useEffect } from 'react';
import "./Login.css";
import Button1 from "../components/Button1";
import {Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from '../context/AlertContext';
import { useAuthContext } from '../hooks/useAuthContext';
import { useUserContext } from '../hooks/useUserContext';
import { useRegister } from "../hooks/useRegister";
import { useLogin } from "../hooks/useLogin";
import { useCreateUser } from "../hooks/useCreateUser";
import { useGetUser } from "../hooks/useGetUser";

const google = require('../images/google.png');
const apple = require('../images/apple.png');
const email = require('../images/email.png');
const phone = require('../images/phone-icon.png');


const Login: React.FC = () => {
  const navigate = useNavigate();
  //const { user } = userAuthContext();
  const { state: { user } } = useAuthContext();
  //const { state: { userData } } = useUserContext();

  const { dispatch: userDispatch } = useUserContext();

  const { googleSignIn, appleSignIn, emailSignIn, setUpRecaptha } = useLogin();
  const { alertState } = useAlertContext();
  const { createUser } = useCreateUser();
  const { getUser } = useGetUser();

  function handleClickEmail(): void {
    navigate("/login/email");
  }


  function handleClickPhone(): void {
    navigate("/login/phone");
  }

  function handleClickRegister(): void {
    navigate("/");
  }

  const updateUserData = async (accessToken: any) => {
    //comsole.log("3. Helloooooooo!!!!!!!");
    console.log("accessToken in Login.tsx: ", accessToken);
    const userData = await getUser(accessToken);

    console.log("userData in userData: ", userData);
    localStorage.setItem('userData', JSON.stringify(userData));
    userDispatch({ type: 'LOGIN', payload: userData });
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();

      //console.log("Login.tsx")
      //comsole.log("1. Helloooooooo!!!!!!!");

      //await updateUserData(user.stsTokenManager.accessToken);
      //comsole.log("2. Helloooooooo!!!!!!!");

      //await createUser(user.stsTokenManager.accessToken, userData);

      
      //if(user != null) {
      navigate("/home");
      //}
      console.log("user: ", user );
    } catch (error) {
        /*const { showAlert } = useAlertContext(); // Get the showAlert function from the AlertContext
        showAlert("An error occurred during sign-in. Please try again."); // Show the error alert message*/
        console.log(error);
    }
  };

  const handleAppleLogin = async () => {
    try {
      await appleSignIn();
      if(user != null) {
        navigate("/home");
      }
      console.log("user: ", user );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /*if (user != null) {
      navigate("/home");
    }
    else{
      console.log("user is: " , null)
    }*/
  }, [user]);


  return (
    <div>

      <div className="wrapper login-back">
          <div className="content">
            <div className="login-title">Login</div>
            <div className="login-content">
              <div className="login-with">Login with:</div>
                <div className="login-with-btn" >
                  <div className="inside-btn" onClick={handleGoogleSignIn}>
                    <img src={google} alt=""  className="img1"/>
                    Google
                  </div>
                </div>
                <div className="login-with-btn">
                  <div className="inside-btn" >
                    <img src={apple} alt="" className="img2"/>
                    Apple
                  </div>
                </div>
                <div className="login-with-btn">
                  <div className="inside-btn" onClick={handleClickEmail}>
                    <img src={email} alt="" className="img3"/>
                    Email
                  </div>
                </div>
                <div className="login-with-btn">
                  <div className="inside-btn" onClick={handleClickPhone} >
                    <img src={phone} alt="" className="img4"/>
                    Phone
                  </div>
                </div>
              </div>
              <div className="have-account">
                <div className="a1">Don't have an account?</div>
                <div className="button-account a1" onClick={handleClickRegister}><Button1 name="Register" url=""/></div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Login;